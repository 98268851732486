import isNil from '../is-nil';
import isNumber from '../is-number';
import isBoolean from '../is-boolean';

/**
 * Checks if an argument is an empty object (no keys), empty array (no items) or empty string ('')
 *
 * @type {(value: any) => boolean}
 */
const isEmpty = (value) => {
  if (isNil(value)) return true;
  if (Array.isArray(value) || typeof value === 'string') return value.length === 0;

  if (Object.keys(value).length === 0) return true;
  return isNumber(value) || isBoolean(value);
};

export default isEmpty;

import { to } from 'await-to-js';
import log from 'loglevel';
import isNotFunction from '~/utils/object/is-not-function';

// Poor man's pattern matching: if there is a word after a PNR, let's assume it is a last name (probably it is)
const PNR_AND_LAST_NAME_REGEX = /[\dA-Za-z]{6}\/\w+/;
const CHECK_IN_URL_FRAGMENT = 'check-in';

let isEnabled = false;
let Sentry = null;

const callOnlyIfEnabled =
  (method) =>
  (...args) => {
    if (!isEnabled) return;
    if (isNotFunction(Sentry[method]))
      return log.error(`Sentry.${method} is not a function.`);
    return Sentry[method](...args);
  };

export const captureException = callOnlyIfEnabled('captureException');

export const configureScope = callOnlyIfEnabled('configureScope');

export const setUser = callOnlyIfEnabled('setUser');

export const setup = async (config, Vue) => {
  const {
    isSentryEnabled,
    isSentryEnabledOnCheckin,
    sentryDsn: dsn,
    sentryEnvironment: environment,
    sentryTracesSampleRate,
  } = config;
  const isCheckIn = window.location.href.includes(CHECK_IN_URL_FRAGMENT);
  if (!isSentryEnabled || !(isCheckIn && isSentryEnabledOnCheckin)) return;

  const tracesSampleRate = Number(sentryTracesSampleRate);

  let error;
  [error, Sentry] = await to(import('./lib'));
  if (error) return log.error('Failed to load logging tools.');

  Sentry.init({
    dsn,
    release: `${$PACKAGE_NAME}@${$PACKAGE_VERSION}`,
    environment: environment ?? 'development',
    allowUrls: ['webpack', /wizzair\.(test|com)/, /(webdev-)?wizzair\.local/],
    autoBreadcrumbs: {
      dom: false,
    },

    beforeSend(event) {
      const fileName = event.exception?.values[0]?.stacktrace?.frames[0]?.filename ?? '';
      const endOfThirdPartyFileWhichSpamsSentry = 'ed0011010c75';
      if (fileName.endsWith(endOfThirdPartyFileWhichSpamsSentry)) {
        return null;
      }

      if (event.request?.url) {
        // E.g. https://wizzair.test:3000/en-gb/check-in-flow#/pnr/lastName/outbound/passengers
        // URL gets encoded in Sentry, which is why there are no brackets around PNR and last name
        event.request.url = event.request.url.replace(
          PNR_AND_LAST_NAME_REGEX,
          'pnr/lastName'
        );
      }

      if (event.exception?.values?.length) {
        event.exception.values.forEach((exception) => {
          exception.value = exception.value.replace(
            PNR_AND_LAST_NAME_REGEX,
            '<pnr>/<lastName>'
          );
        });
      }

      return event;
    },

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'navigation') {
        // E.g. /en-gb/check-in#/<pnr>/<lastName>/outbound/passengers
        breadcrumb.data.from = breadcrumb.data.from.replace(
          PNR_AND_LAST_NAME_REGEX,
          '<pnr>/<lastName>'
        );
        breadcrumb.data.to = breadcrumb.data.to.replace(
          PNR_AND_LAST_NAME_REGEX,
          '<pnr>/<lastName>'
        );
      }

      return breadcrumb;
    },

    integrations: [
      new Sentry.VueIntegration({
        Vue,
        tracing: tracesSampleRate > 0,
        tracingOptions: {
          trackComponents: true,
        },
        logErrors: $ENV === 'development',
      }),
      new Sentry.BrowserTracing({
        tracingOrigins: ['be.wizzair.com'],
      }),
    ],

    tracesSampleRate,

    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_us\/all\.js/i,
      /connect\.facebook\.net\/en_us\/fbevents\.js/i,
      /googletagmanager\.com\/gtag\/js/i,
      /analytics\.tiktok\/.com\/i18n\/pixel\/events\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  Sentry.setTag('page_locale', window.page.culture);

  isEnabled = true;
};

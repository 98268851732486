import curry from '../curry';
import { _defaultTo } from '../default-to';
import { _path } from '../path';

/**
 * @typedef {(defaultValue: any, p: string, obj: Object<string, any>) => any} PathOrFn
 */

/**
 * @type PathOrFn
 */
export const _pathOr = (defaultValue, p, obj) => _defaultTo(defaultValue, _path(p, obj));

/**
 * If the given, non-null object has a value at the given path, returns the
 * value at that path. Otherwise returns the provided default value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pathOr.js
 *
 * @type PathOrFn
 * @example
 *
 *      pathOr('N/A', 'a.b', {a: {b: 2}}); //=> 2
 *      pathOr('N/A', 'a.b', {c: {b: 2}}); //=> "N/A"
 */
const pathOr = curry(_pathOr);

export default pathOr;

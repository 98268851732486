import { LANGUAGE_CODE_GEORGIAN } from '~/constants';
import path from '~/utils/fp/path';
import pathOr from '~/utils/fp/path-or';
import compose from '~/utils/fp/compose';
import isNotEmpty from '~/utils/object/is-not-empty';

const localeProp = (prop) => path(`locale.${prop}`);

const localePropOr = (defaultValue, prop) => pathOr(defaultValue, `locale.${prop}`);

/**
 * @type {(state: State) => string}
 */
export const languageCode = localeProp('languageCode');

/**
 * @type {(state: State) => string[]}
 */
export const currencies = localeProp('currencies');

export const hasCurrencies = compose(isNotEmpty, currencies);

/**
 * @type {(state: State) => string[]}
 */
export const currenciesOnActiveStations = localePropOr([], 'currenciesOnActiveStations');

export const hasCurrenciesOnActiveStations = compose(
  isNotEmpty,
  currenciesOnActiveStations
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const availableLocales = localeProp('availableLocales');

export const hasAvailableLocales = compose(isNotEmpty, availableLocales);

/**
 * @type {(state: State) => boolean}
 */
export const shouldRefreshAncillaries = localeProp('shouldRefreshAncillaries');

/**
 * @type {(state: State) => boolean}
 */
export const isSelectedLanguageGeorgian = (state) =>
  languageCode(state) === LANGUAGE_CODE_GEORGIAN;

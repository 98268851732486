/**
 * Checks if the input value is `null` or `undefined`.
 *
 * @example
 *
 *      isNil(null);      // => true
 *      isNil(undefined); // => true
 *      isNil(0);         // => false
 *      isNil([]);        // => false
 */

const isNil = (value: unknown): value is Nil => value == null;

export default isNil;

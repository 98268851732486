import curry from '../curry';
import { _deepMergeWithKey } from '../deep-merge-with-key';

/**
 * @typedef {(leftObj: Object<string, any>, rightObj: Object<string, any>) => Object<string, any>} DeepMergeLeftFn
 */

/**
 * @type DeepMergeLeftFn
 */
export const _deepMergeLeft = (leftObj, rightObj) =>
  _deepMergeWithKey((key, leftVal, rightVal) => leftVal, leftObj, rightObj);

/**
 * Creates a new object with the own properties of the first object merged with
 * the own properties of the second object. If a key exists in both objects:
 * - and both values are objects, the two values will be recursively merged
 * - otherwise the value from the first object will be used.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/mergeDeepLeft.js
 *
 * @type DeepMergeLeftFn
 * @example
 *
 *      deepMergeLeft({ name: 'fred', age: 10, contact: { email: 'moo@example.com' }},
 *                      { age: 40, contact: { email: 'baa@example.com' }});
 *      //=> { name: 'fred', age: 10, contact: { email: 'moo@example.com' }}
 */
const deepMergeLeft = curry(_deepMergeLeft);

export default deepMergeLeft;

/**
 * Checks if the argument has a type of Function
 *
 * @type {(value: any) => boolean}
 * @example
 *
 *      isFunction(() => {})
 *      // => true
 *
 *      isFunction(async () => { await ... })
 *      // => true
 *
 *      isFunction(1)
 *      // => false
 */
const isFunction = (value) =>
  ['[object Function]', '[object AsyncFunction]'].includes(
    Object.prototype.toString.call(value)
  );

export default isFunction;

import isFunction from '~/utils/object/is-function';
import curry from '../curry';

/**
 * @typedef {<T>(fromIndex: number, toIndex: number, arr: T[]) => T[]} SliceFn
 */

/**
 * @type SliceFn
 */
export const _slice = (fromIndex, toIndex, list) =>
  isFunction(list.slice)
    ? list.slice(fromIndex, toIndex)
    : Array.prototype.slice.call(list, fromIndex, toIndex);

/**
 * Returns the elements of the given list or string (or object with a `slice`
 * method) from `fromIndex` (inclusive) to `toIndex` (exclusive).
 *
 * Dispatches to the `slice` method of the third argument, if present.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/slice.js
 *
 * @type SliceFn
 * @example
 *
 *      slice(1, 3, ['a', 'b', 'c', 'd']);        //=> ['b', 'c']
 *      slice(1, Infinity, ['a', 'b', 'c', 'd']); //=> ['b', 'c', 'd']
 *      slice(0, -1, ['a', 'b', 'c', 'd']);       //=> ['a', 'b', 'c']
 *      slice(-3, -1, ['a', 'b', 'c', 'd']);      //=> ['b', 'c']
 *      slice(0, 3, 'ramda');                     //=> 'ram'
 */
const slice = curry(_slice);

export default slice;

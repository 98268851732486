/**
 * Wraps the specified `fn` in a function which will have `length` number of parameters.
 *
 * Implementation based on https://github.com/blakeembrey/arity
 *
 * @type {(length: number, fn : Function) => Function}
 */
const arity = (length, fn) => {
  switch (length) {
    case 0:
      return arity0(fn);
    case 1:
      return arity1(fn);
    case 2:
      return arity2(fn);
    case 3:
      return arity3(fn);
    case 4:
      return arity4(fn);
    case 5:
      return arity5(fn);
    case 6:
      return arity6(fn);
    case 7:
      return arity7(fn);
    case 8:
      return arity8(fn);
    case 9:
      return arity9(fn);
    case 10:
      return arity10(fn);
    // no default
  }

  throw new Error('Invalid `length` specified. Only [0..10] lengths are supported.');
};

export default arity;

export const arity0 = (fn) =>
  function () {
    return callFn(fn, arguments);
  };

export const arity1 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a) {
    return callFn(fn, arguments);
  };

export const arity2 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b) {
    return callFn(fn, arguments);
  };

export const arity3 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c) {
    return callFn(fn, arguments);
  };

export const arity4 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d) {
    return callFn(fn, arguments);
  };

export const arity5 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e) {
    return callFn(fn, arguments);
  };

export const arity6 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e, f) {
    return callFn(fn, arguments);
  };

export const arity7 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e, f, g) {
    return callFn(fn, arguments);
  };

export const arity8 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e, f, g, h) {
    return callFn(fn, arguments);
  };

export const arity9 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e, f, g, h, i) {
    return callFn(fn, arguments);
  };

export const arity10 = (fn) =>
  // WARN: don't ever think about it converting this to an arrow function, arguments
  //  are not supported there
  function (a, b, c, d, e, f, g, h, i, j) {
    return callFn(fn, arguments);
  };

const callFn = (fn, args) => {
  return fn(...Array.from(args));
};

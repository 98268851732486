import arity from '../arity';
import isPlaceholder from '../is-place-holder';

/**
 * Returns a curried equivalent of the provided function, with the specified
 * arity. The curried function has two unusual capabilities. First, its
 * arguments needn't be provided one at a time. If `g` is `curryN(3, f)`, the
 * following are equivalent:
 *
 *   - `g(1)(2)(3)`
 *   - `g(1)(2, 3)`
 *   - `g(1, 2)(3)`
 *   - `g(1, 2, 3)`
 *
 * Secondly, the special placeholder value `__` may be used to specify
 * "gaps", allowing partial application of any combination of arguments,
 * regardless of their positions. If `g` is as above and `_` is `__`,
 * the following are equivalent:
 *
 *   - `g(1, 2, 3)`
 *   - `g(_, 2, 3)(1)`
 *   - `g(_, _, 3)(1)(2)`
 *   - `g(_, _, 3)(1, 2)`
 *   - `g(_, 2)(1)(3)`
 *   - `g(_, 2)(1, 3)`
 *   - `g(_, 2)(_, 3)(1)`
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/curryN.js
 *
 * note here we are cheating for now :)
 * @type {<T extends any[], U>(length: number, fn: (...args: T) => U) => (...args: T) => U}
 * @example
 *
 *      let addFourNumbers = (a, b, c, d) => a + b + c + d;
 *
 *      let curriedAddFourNumbers = curry(addFourNumbers);
 *      let f = curriedAddFourNumbers(1, 2);
 *      let g = f(3);
 *      g(4); //=> 10
 */
const curryN = (length, fn) => {
  // note: there is no point (yet) to curry 0 or 1 param functions
  if (length <= 1) return fn;
  return arity(length, _curryN(length, [], fn));
};

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/internal/_curryN.js
 */
const _curryN =
  (length, saved, fn) =>
  (...args) => {
    const argsLength = args.length;
    const savedLength = saved.length;
    let leftCount = length;
    const combined = [];
    let argsIdx = 0;
    let combinedIdx = 0;

    while (combinedIdx < savedLength || argsIdx < argsLength) {
      let result;
      const savedArg = saved[combinedIdx];
      if (
        combinedIdx < savedLength &&
        (!isPlaceholder(savedArg) || argsIdx >= argsLength)
      ) {
        result = savedArg;
      } else {
        result = args[argsIdx];
        argsIdx++;
      }

      combined[combinedIdx] = result;

      if (!isPlaceholder(result)) {
        leftCount--;
      }

      combinedIdx++;
    }

    return leftCount <= 0
      ? fn(...combined)
      : arity(leftCount, _curryN(length, combined, fn));
  };

export default curryN;

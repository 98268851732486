import curry from '../curry';
import { _has } from '../has';
import __hasPath from '../_internal/_has-path';

/**
 * @typedef {(prop: string, obj: Object<string, any>) => boolean} HasPath
 */

/**
 * @type HasPath
 */
export const _hasPath = __hasPath(_has);

/**
 * Returns whether or not a path exists in an object. Only the object's
 * own properties are checked.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/hasPath.js
 *
 * @type HasPath
 * @example
 *
 *      hasPath('a.b', {a: {b: 2}});         // => true
 *      hasPath('a.b', {a: {b: undefined}}); // => true
 *      hasPath('a.b', {a: {c: 2}});         // => false
 *      hasPath('a.b', {});                  // => false
 */
const hasPath = curry(_hasPath);

export default hasPath;

import curry from '../curry';

/**
 * @typedef {(prop: string, value: any, obj: Object<string, any>) => Object<string, any>} AssocFn
 */

/**
 * @type AssocFn
 */
export const _assoc = (prop, value, obj) => ({
  ...obj,
  [prop]: value,
});

/**
 * Makes a shallow clone of an object, setting or overriding the specified
 * property with the given value. Note that this copies and flattens prototype
 * properties onto the new object as well. All non-primitive properties are
 * copied by reference.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/assoc.js
 *
 * @type AssocFn
 * @example
 *
 *      assoc('c', 3, {a: 1, b: 2}); //=> {a: 1, b: 2, c: 3}
 */
const assoc = curry(_assoc);

export default assoc;

import { _nth } from '../nth';

/**
 * Returns the first element of the given array.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/head.js
 *
 * @type {<T>(arr: T[]) => T}
 * @example
 *
 *      head(['fi', 'fo', 'fum']); //=> 'fi'
 *      head([]); //=> undefined
 */
const head = (arr) => _nth(0, arr);

export default head;

import isString from '~/utils/object/is-string';
import curry from '../curry';

/**
 * @typedef {<T>(offset: number, list: T[]|string) => T | string} NthFn
 */

/**
 * @type NthFn
 */
export const _nth = (offset, list) => {
  const index = offset < 0 ? list.length + offset : offset;
  return isString(list) ? list.charAt(index) : list[index];
};

/**
 * Returns the nth element of the given list or string. If n is negative the
 * element at index length + n is returned.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/nth.js
 *
 * @type NthFn
 * @example
 *
 *      const list = ['foo', 'bar', 'baz', 'quux'];
 *      nth(1, list); //=> 'bar'
 *      nth(-1, list); //=> 'quux'
 *      nth(-99, list); //=> undefined
 *
 *      nth(2, 'abc'); //=> 'c'
 *      nth(3, 'abc'); //=> ''
 */
const nth = curry(_nth);

export default nth;

import { _has } from '../has';

/**
 * Creates a new object with the own properties of the two provided objects. If
 * a key exists in both objects, the provided function is applied to the key
 * and the values associated with the key in each object, with the result being
 * used as the value associated with the key in the returned object.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/mergeWithKey.js
 *
 * @type {(fn: (a: any, b: any) => any, left: Object<string, any>, right: Object<string, any>) => Object<string, any>}
 * @example
 *
 *      let concatValues = (k, l, r) => k == 'values' ? concat(l, r) : r
 *      mergeWithKey(concatValues,
 *                     { a: true, thing: 'foo', values: [10, 20] },
 *                     { b: true, thing: 'bar', values: [15, 35] });
 *      //=> { a: true, b: true, thing: 'bar', values: [10, 20, 15, 35] }
 */
const mergeWithKey = (fn, left, right) => {
  const result = {};

  Object.entries(left).forEach(([key, leftVal]) => {
    result[key] = _has(key, right) ? fn(key, leftVal, right[key]) : leftVal;
  });

  Object.entries(right)
    .filter(([key]) => !_has(key, result))
    .forEach(([key, rightVal]) => {
      result[key] = rightVal;
    });

  return result;
};

export default mergeWithKey;

import curry from '../curry';

/**
 * @typedef {(f: (...args) => any, g: (...args) => any) => any} EitherFn
 */

/**
 * @type EitherFn
 */
export const _either =
  (f, g) =>
  (...args) =>
    f(...args) || g(...args);

/**
 * A function wrapping calls to the two functions in an `||` operation,
 * returning the result of the first function if it is truth-y and the result
 * of the second function otherwise. Note that this is short-circuited,
 * meaning that the second function will not be invoked if the first returns a
 * truth-y value.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/either.js
 *  . skipped the applicative functor functionality for now
 *
 * @type EitherFn
 * @example
 *
 *      let gt10 = x => x > 10;
 *      let even = x => x % 2 === 0;
 *      let f = either(gt10, even);
 *      f(101); //=> true
 *      f(8); //=> true
 */
const either = curry(_either);

export default either;

import pipe from '../pipe';

/**
 * Performs right-to-left function composition. The last argument may have
 * any arity; the remaining arguments must be unary.
 *
 * note: the result of compose is not automatically curried
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/compose.js
 *
 * @type {<T extends any[], U>(...fns: Function[]) => (...args: T) => U}
 * @example
 *
 *      const classyGreeting = (firstName, lastName) => "The name's " + lastName + ", " + firstName + " " + lastName
 *      const yellGreeting = compose(toUpper, classyGreeting);
 *      yellGreeting('James', 'Bond'); //=> "THE NAME'S BOND, JAMES BOND"
 *
 *      compose(Math.abs, add(1), multiply(2))(-4) //=> 7
 */
// note: here we can reverse in place because of `...fns` is local
const compose = (...fns) => pipe(...fns.reverse());

export default compose;

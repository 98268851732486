import isNil from '~/utils/object/is-nil';
import curry from '../curry';

/**
 * @typedef {<T, U>(defaultValue: U, value: T) => T|U} DefaultToFn
 */

/**
 * @type DefaultToFn
 */
export const _defaultTo = (defaultValue, value) => (isNil(value) ? defaultValue : value);

/**
 * Returns the second argument if it is not `null`, `undefined` or `NaN`;
 * otherwise the first argument is returned.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/defaultTo.js
 *
 * @type DefaultToFn
 * @example
 *
 *      const defaultTo42 = defaultTo(42);
 *
 *      defaultTo42(null);  //=> 42
 *      defaultTo42(undefined);  //=> 42
 *      defaultTo42(false);  //=> false
 *      defaultTo42('Ramda');  //=> 'Ramda'
 *      // parseInt('string') results in NaN
 *      defaultTo42(parseInt('string')); //=> 42
 */
const defaultTo = curry(_defaultTo);

export default defaultTo;

/**
 * Checks if the argument has a type of String
 *
 * @type {(value: any) => boolean}
 * @example
 *
 *      isString('abc')
 *      // => true
 *
 *      isString(1)
 *      // => false
 */
const isString = (value) => Object.prototype.toString.call(value) === '[object String]';

export default isString;

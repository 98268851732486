import log from 'loglevel';
import isEmpty from '~/utils/object/is-empty';
import alter from '~/utils/fp/alter';
import { isServer } from '~/utils/ssr';

const wrap = (type = 'local', fn) =>
  function storageWrapper(...args) {
    if (isServer()) return;

    if (`${type}Storage` in window) {
      return fn(...args);
    }
    log.error(`${type}Storage not supported`);
  };

const apiWrapper = (type = 'local') =>
  ['getItem', 'setItem', 'removeItem', 'clear'].reduce((obj, method) => {
    obj[method] = wrap(type, (...args) => globalThis[`${type}Storage`][method](...args));
    return obj;
  }, {});

const safeLocalStorage = apiWrapper('local');
const safeSessionStorage = apiWrapper('session');

// flash storage
// -------------

// (flash storage is for volatile vars, the first read will remove the var as well.
// Do not use it for anything really important)
export const getFlashItem = (key, defaultValue) => {
  if (isServer()) return;

  let valS;
  try {
    valS = JSON.parse(sessionStorage.getItem(key));
    sessionStorage.removeItem(key);
  } catch (error) {
    log.error(error);
  }
  return valS || defaultValue;
};

export const setFlashItem = (key, value) => {
  safeSessionStorage.setItem(key, JSON.stringify(value));
};

// session storage
// ---------------

export const getSessionItemOr = (defaultValue, key) =>
  getSessionItem(key) ?? defaultValue;

export const getSessionItem = (key) => {
  let item = safeSessionStorage.getItem(key); // may be undefined
  try {
    item = JSON.parse(item);
  } catch {
    // log.error(err);
  }
  return item;
};

export const setSessionItem = (key, data) => {
  safeSessionStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));
};

export const removeSessionItem = (key) => {
  safeSessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  safeSessionStorage.clear();
};

export const clearSessionStorageWithWhiteList = (whitelistArray) => {
  const sessionItemsToKeep = getSessionItems(whitelistArray);
  safeSessionStorage.clear();
  Object.keys(sessionItemsToKeep).forEach((key) =>
    setSessionItem(key, sessionItemsToKeep[key])
  );
};

export const getSessionItems = (whitelistArray) => {
  if (!('sessionStorage' in window)) {
    return null;
  }
  const session = {};
  const keys = whitelistArray || Object.keys(sessionStorage);

  keys.forEach((key) => {
    session[key] = getSessionItem(key);
  });
  return session;
};

// saves the session to the local cloud (tm) where colorful unicorns dwell
export const saveSession = () => {
  const session = getSessionItems();
  if (!session) {
    return false;
  }
  try {
    setLocalItem('__sessionStorage__', session);
  } catch {
    log.error(
      'could not save session item, trying to remove cartrawler item and retrying'
    );
    removeLocalItem('ct.smartblock.rentals.ct.vehlocsearch');
    removeLocalItem('ct.smartblock.rentals.ota.vehavailrate');
    setLocalItem('__sessionStorage__', session);
  }
  return true;
};

// restore the session from the land of unicorns, this is real magic
export const restoreSession = () => {
  const session = getLocalItem('__sessionStorage__');
  if (!session) {
    return false;
  }
  Object.keys(session)
    .filter((key) => key !== 'redirect-to')
    .forEach((key) => setSessionItem(key, session[key]));
  removeLocalItem('__sessionStorage__');
  return true;
};

// local storage
// -------------

export const getLocalItem = (key, checkExpiry = false) => {
  if (isServer() || !('localStorage' in window)) return;

  const record = JSON.parse(localStorage.getItem(key));
  const now = Date.now();
  if (!record || (checkExpiry && record.timestamp < now)) {
    return false;
  }
  return record.data;
};

export const setLocalItem = (key, data, expiry = 0) => {
  const now = Date.now();
  const store = {
    data,
    timestamp: now + expiry * 60 * 1000,
  };
  safeLocalStorage.setItem(key, JSON.stringify(store));
};

export const removeLocalItem = (key) => {
  safeLocalStorage.removeItem(key);
};

export const setLocalItemRaw = (key, data) => {
  if (typeof data !== 'string') {
    log.warn(`storage setLocalItemRaw: data (${data}) is not a string`);
  }
  safeLocalStorage.setItem(key, data);
};

export const getLocalItemRaw = (key) => {
  return safeLocalStorage.getItem(key);
};

export const onStoredValueChange = (key, response) => {
  window.addEventListener('storage', (event) => {
    if (event.key === key && event.oldValue !== event.newValue) {
      response();
    }
  });
};

/**
 * @type {(key: String, value: String, attributes: Object ) => void}
 */
export const setCookie = (key, value, attributes = {}) => {
  if (!document) return;
  [key, value].forEach((item) => {
    if (isEmpty(item)) return log.warn(`${item} is invalid or missing`);
    if (typeof item !== 'string') return log.warn(`data (${item}) is not a string`);
  });

  attributes = alter(attributes, { maxAge: null, path: null });
  const _maxAge = !attributes.maxAge ? '' : `max-age=${attributes.maxAge};`;
  const _path = !attributes.path ? '' : `path=${attributes.path}`;
  document.cookie = `${key}=${value}; ${_maxAge} ${_path}`;
};

/**
 * @type {(key: String) => String}
 */
export const getCookie = (key) => {
  if (!document) return;
  const regex = new RegExp(`(^|;|\\s)${key}=([^;]+)`);
  return document.cookie.match(regex)?.[2];
};

export const deleteCookie = (name, domain) => {
  document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

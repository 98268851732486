import isObject from '~/utils/object/is-object';
import curry from '../curry';
import mergeWithKey from '../merge-with-key';

/**
 * @typedef {(fn: (a: any, b: any) => any, left: Object<string, any>, right: Object<string, any>) => Object<string, any>} DeepMergeWithKeyFn
 */

/**
 * @type DeepMergeWithKeyFn
 */
export const _deepMergeWithKey = (fn, leftObj, rightObj) =>
  mergeWithKey(
    (key, leftVal, rightVal) =>
      isObject(leftVal) && isObject(rightVal)
        ? _deepMergeWithKey(fn, leftVal, rightVal)
        : fn(key, leftVal, rightVal),
    leftObj,
    rightObj
  );

/**
 * Creates a new object with the own properties of the two provided objects.
 * If a key exists in both objects:
 * - and both associated values are also objects then the values will be
 *   recursively merged.
 * - otherwise the provided function is applied to the key and associated values
 *   using the resulting value as the new value associated with the key.
 * If a key only exists in one object, the value will be associated with the key
 * of the resulting object.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/mergeDeepWithKey.js
 *
 * @type DeepMergeWithKeyFn
 * @example
 *
 *      let concatValues = (k, l, r) => k == 'values' ? concat(l, r) : r
 *      deepMergeWithKey(concatValues,
 *                         { a: true, c: { thing: 'foo', values: [10, 20] }},
 *                         { b: true, c: { thing: 'bar', values: [15, 35] }});
 *      //=> { a: true, b: true, c: { thing: 'bar', values: [10, 20, 15, 35] }}
 */
const deepMergeWithKey = curry(_deepMergeWithKey);

export default deepMergeWithKey;

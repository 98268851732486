import isNumber from '~/utils/object/is-number';
import curry from '../curry';
import compose from '../compose';
import replace from '../replace';

const delimiterRegex = /[.[]/;

/**
 * @typedef {(p: string, obj: Object<string, any>) => any} PathFn
 */

/**
 * @type PathFn
 */
export const _path = (p, obj) => {
  if (isNumber(p)) p = String(p);
  let value = obj;
  let index = 0;
  const splitPath = p
    // to avoid empty '' initial chunk when path starts like '[a]'
    .replace(/^\[/, '')
    .split(delimiterRegex)
    .map(compose(replace(/["']$/, ''), replace(/^["']/, ''), replace(/]$/, '')));

  while (index < splitPath.length) {
    if (value == null) return;
    value = value[splitPath[index]];
    index += 1;
  }

  return value;
};

/**
 * Retrieve the value at a given path.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.26.0/source/path.js
 *
 * @type PathFn
 * @example
 *
 *      path('a.b', { a: { b: 2 } }); //=> 2
 *      path('a.b', { c: { b: 2 } }); //=> undefined
 *      // we can also use bracket notation (["a"], ['a'] forms are supported too)
 *      path('[a][b]', { a: { b: 2 } }); //=> 2
 *      // we can mix dot and bracket notation
 *      path('a[b].c', { a: { b: { c: 3 } } }); //=> 3
 *      // we can access elements of an array as well
 *      path('a.b[2]', { a: 1, b: [2, 3, 4]}); //=> 4
 *      // we can access first level array elements via passing in an index as path
 *      path(1, [1, 2, 3, 4]) //=> 2
 */
const path = curry(_path);

export default path;

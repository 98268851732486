import isNil from '~/utils/object/is-nil';
import compose from '~/utils/fp/compose';
import replace from '~/utils/fp/replace';

const delimiterRegex = /[.[]/;

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/hasPath.js
 *
 * @type {(hasFn: (prop: string, value: Object<string, any>) => boolean) => (prop: string, obj: Object<string, any>) => boolean}
 */
const _hasPath = (hasFn) => (path, obj) => {
  const splitPath = path
    // to avoid empty '' initial chunk when path starts like '[a]'
    .replace(/^\[/, '')
    .split(delimiterRegex)
    .map(compose(replace(/["']$/, ''), replace(/^["']/, ''), replace(/]$/, '')));

  let value = obj;
  let index = 0;
  while (index < splitPath.length) {
    const prop = splitPath[index];
    if (isNil(value) || !hasFn(prop, value)) return false;
    value = value[prop];
    index += 1;
  }

  return true;
};

export default _hasPath;

import log from 'loglevel';
import * as sentry from '~/sentry';
import has from '../fp/has';
import hasPath from '../fp/has-path';
import either from '../fp/either';

const capturedExceptions = new WeakSet();
const loggedErrors = new WeakSet();

export const captureAndLogException = (...args) => {
  log.error(...args);
  args.filter(isError).forEach((error) => captureException(error));
};

export const captureException = (error) => {
  // note: we only want to capture an error once
  if (capturedExceptions.has(error)) return;
  capturedExceptions.add(error);

  const {
    isApiError = false,
    isAxiosError = false,
    code,
    status,
    statusText,
    data: responseData,
    config,
  } = error;

  // note: we are not interested in BE errors they are already logged and we won't
  //  want to run out of Sentry budget with duplicate logs
  if (isApiError) return;

  const errorKind = getErrorKind(error);
  const { validationCodes, reason } = responseData ?? {};
  const { url, baseURL, method, params } = config ?? {};

  sentry.captureException(error, {
    contexts: {
      errorDetails: {
        kind: errorKind,
        isApiError,
        isAxiosError,
        ...(has('status', error)
          ? { status: `${status}${statusText ? ` (${statusText})` : ''}` }
          : {}),
        ...(has('code', error) ? { code } : {}),
        ...(hasPath('config.url', error) ? { url: `[${method}] ${baseURL}/${url}` } : {}),
        // todo: hope this doesn't contain user specific info :)
        ...(hasPath('config.params', error) ? { params } : {}),
        // ...(hasPath('config.headers', errorResponse) ? { requestHeaders: config.headers } : {}),
        // note: this might expose user data eg username/password so don't just enable
        // ...(hasPath('config.data', errorResponse) ? { requestData: config.data } : {}),
        // todo: is this useful?
        // ...(has('headers', errorResponse) ? { responseHeaders: errorResponse.headers } : {}),
        ...(isApiError && hasPath('data.validationCodes', error)
          ? { validationCodes }
          : {}),
        ...(isApiError && hasPath('data.reason', error) ? { reason } : {}),
      },
    },
    tags: {
      error_kind: errorKind,
    },
  });
};

/**
 * Patching log.error to add some extra functionality:
 *  - errors will be logged only once to not trash console
 *  - XHR and API errors will be logged in a customized format
 */
export const patchLogError = () => {
  const origLogError = log.error;

  log.error = (...args) => {
    // note: we only want to log an error once
    args = args.filter(either(isNotError, isNotLoggedError)).map((arg) => {
      if (isNotError(arg)) return arg;
      const error = arg;
      loggedErrors.add(error);
      return error.isAxiosError ? formatAxiosError(error) : error;
    });

    origLogError(...args);
  };
};

const formatAxiosError = (error) => {
  const { isApiError = false } = error;
  return isApiError ? toBackendLogFormat(error) : toXhrLogFormat(error);
};

/**
 * WIZZ: Request failed with status code 400
 *     POST https://be.uat.wizzair.local/10.43.0/Api/customer/login
 *     400 (Bad Request) [LoginFailed], reason: alma
 */
const toBackendLogFormat = (error) => {
  const { isApiError = false } = error;
  if (!isApiError) return error;

  const { message, status, statusText, config, data } = error;
  const { method, url, baseURL } = config;
  const { validationCodes, reason } = data ?? {};

  let text = '';
  text += `${message}`;
  text += `\n    ${method.toUpperCase()} ${baseURL}/${url}`;
  text += `\n    ${status}`;
  text += statusText ? ` (${statusText})` : '';
  text += validationCodes ? ` [${validationCodes}]` : '';
  text += reason ? `, reason: ${reason}` : '';
  return text;
};

/**
 * WIZZ: Network Error
 *     POST https://be.uat.wizzair.local/10.43.0/Api/customer/login
 *     0 (...) XHR
 */
const toXhrLogFormat = (error) => {
  const { isAxiosError = false, isApiError = false } = error;
  if (!isAxiosError || isApiError) return error;

  const { message, status, statusText, config } = error;
  const { method, url, baseURL } = config;
  const kind = getErrorKind(error);

  let text = '';
  text += `${message}`;
  text += `\n    ${method.toUpperCase()} ${baseURL}/${url}`;
  text += `\n    ${status}`;
  text += statusText ? ` (${statusText})` : '';
  text += ` ${kind.toUpperCase()}`;
  return text;
};

const getErrorKind = (error) => {
  const { isApiError = false, isAxiosError = false } = error;
  if (isApiError) return 'backend';
  if (isAxiosError) return 'xhr';
  return 'other';
};

const isNotLoggedError = (value) => isError(value) && !loggedErrors.has(value);

const isError = (value) => value instanceof Error;

const isNotError = (value) => !isError(value);

export const setNewRelicErrorHandler = () => {
  if (!window.newrelic) return;

  window.newrelic.setErrorHandler((err) => isThirdpartyError(err));
};

const isThirdpartyError = (error) => {
  const blacklistItems = [
    'pinimg',
    'recaptcha',
    'webchat-sdk',
    'cdn.cookielaw',
    'securitytrfx',
    's-agent.newrelic.com',
    'analytics.tiktok',
    'googletagmanager',
    'connect.facebook.net',
    'Unhandled Promise Rejection: Loading chunk',
    'Unhandled Promise Rejection: Timeout (u)',
  ];
  return blacklistItems.some(
    (blItem) => error.message?.includes(blItem) || error.stack?.includes(blItem)
  );
};

export const logNewRelicError = (message) => {
  if (!window.newrelic) return;

  window.newrelic.log(message, {
    level: 'error',
  });
};

import curry from '../curry';

/**
 * @typedef {(regex: string|RegExp, replacement: string: str: string) => string} ReplaceFn
 */

/**
 * @type ReplaceFn
 */
export const _replace = (regex, replacement, str) => str.replace(regex, replacement);

/**
 * Replace a substring or regex match in a string with a replacement.
 *
 * The first two parameters correspond to the parameters of the
 * `String.prototype.replace()` function, so the second parameter can also be a
 * function.
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/replace.js
 *
 * @type ReplaceFn
 * @example
 *
 *      replace('foo', 'bar', 'foo foo foo'); //=> 'bar foo foo'
 *      replace(/foo/, 'bar', 'foo foo foo'); //=> 'bar foo foo'
 *
 *      // Use the "g" (global) flag to replace all occurrences:
 *      replace(/foo/g, 'bar', 'foo foo foo'); //=> 'bar bar bar'
 */
const replace = curry(_replace);

export default replace;

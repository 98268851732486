/* eslint object-property-newline: 0 */

// include localization data in the vendor build pack (where constants are)
import { metadata } from '@prepair/locale-support';
import { FARE_LOCK_FLOW_SELECTED_FLIGHTS } from '~/router/booking/constants';
import { isServer } from '~/utils/ssr';

// Constants for expressing human-readable intervals in their respective number of seconds.

export const YEAR_IN_DAYS = 365;
export const YEAR_IN_MONTHS = 12;

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_SECONDS = 60;
export const MINUTE_IN_MILLISECONDS = SECOND_IN_MILLISECONDS * MINUTE_IN_SECONDS;
export const HOUR_IN_MINUTES = 60;
export const HOUR_IN_SECONDS = HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
export const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS;
export const YEAR_IN_SECONDS = YEAR_IN_DAYS * DAY_IN_SECONDS;

export const KEY_ENTER = 'Enter';
export const KEY_TAB = 'Tab';
export const KEY_SPACE = ' ';
export const KEY_ARROW_UP = 38;
export const KEY_ARROW_DOWN = 'ArrowDown';
export const KEY_ARROW_LEFT = 'ArrowLeft';
export const KEY_ARROW_RIGHT = 'ArrowRight';

export const KEY_CODE_ENTER = 13;
export const KEY_CODE_TAB = 9;
export const KEY_CODE_SPACE = 32;
export const KEY_CODE_ARROW_UP = 38;
export const KEY_CODE_ARROW_DOWN = 40;
export const KEY_CODE_ARROW_LEFT = 37;
export const KEY_CODE_ARROW_RIGHT = 39;

export const DIRECTION_BOTH = 'both';
export const DIRECTION_OUTBOUND = 'outbound';
export const DIRECTION_RETURN = 'return';
export const DIRECTION_INBOUND = 'inbound';

export const HEADER_NAME_IS_GROUP_BOOKING = 'X-Is-Group-Booking';

export const BOTH_FLIGHT_NAME = DIRECTION_BOTH;
export const OUTBOUND_FLIGHT_NAME = DIRECTION_OUTBOUND;
export const RETURN_FLIGHT_NAME = DIRECTION_RETURN;
export const otherFlightNameMap = new Map([
  [OUTBOUND_FLIGHT_NAME, RETURN_FLIGHT_NAME],
  [RETURN_FLIGHT_NAME, OUTBOUND_FLIGHT_NAME],
]);
export const OUTBOUND_FLIGHT = `${OUTBOUND_FLIGHT_NAME}Flight`;
export const RETURN_FLIGHT = `${RETURN_FLIGHT_NAME}Flight`;

export const DEPARTURE_DATE = 'departureDate';
export const RETURN_DATE = 'returnDate';

export const CARRIER_CODE_LENGTH = 2;
export const FLIGHT_NUMBER_LENGTH = 4;
export const OPSUFFIX_LENGTH = 1;

export const CARRIER_WIZZ_UK = 'w9';
export const CARRIER_WIZZ_HUN = 'w6';
export const CARRIER_WIZZ_AD = '5w';
export const CARRIER_WIZZ_MT = 'w4';

export const CARRIER_CODES = [
  CARRIER_WIZZ_UK,
  CARRIER_WIZZ_HUN,
  CARRIER_WIZZ_AD,
  CARRIER_WIZZ_MT,
];

export const CARRIERS = {
  [CARRIER_WIZZ_HUN.toUpperCase()]: 'Wizz Air Hungary',
  [CARRIER_WIZZ_UK.toUpperCase()]: 'Wizz Air UK',
  [CARRIER_WIZZ_AD.toUpperCase()]: 'Wizz Air Abu Dhabi',
  [CARRIER_WIZZ_MT.toUpperCase()]: 'Wizz Air Malta',
};
export const PACKAGE_TRAVEL_UNLICENSED_CARRIERS = [CARRIER_WIZZ_AD];
export const STATION_DEPARTURE = 'departure';
export const STATION_ARRIVAL = 'arrival';

export const STATION_TO_FLIGHT_NAME_MAP = new Map();
STATION_TO_FLIGHT_NAME_MAP.set(STATION_DEPARTURE, OUTBOUND_FLIGHT_NAME);
STATION_TO_FLIGHT_NAME_MAP.set(STATION_ARRIVAL, RETURN_FLIGHT_NAME);

export const MIN_DATE_OF_BIRTH_YEARS = -100;
export const MAX_DATE_OF_BIRTH_YEARS = -14;

export const MIN_DATE_OF_BIRTH_YEARS_FOR_CHILD = -14;
export const MAX_DATE_OF_BIRTH_YEARS_FOR_CHILD = -2;

export const MIN_DATE_OF_BIRTH_YEARS_FOR_INFANT = -2;
export const MAX_DATE_OF_BIRTH_YEARS_FOR_INFANT = 0;

export const MIN_DATE_OF_ISSUE_YEARS = -20;
export const MAX_DATE_OF_ISSUE_YEARS = 0;

export const MIN_DATE_OF_EXPIRY_YEARS = 0;
export const MAX_DATE_OF_EXPIRY_YEARS = 75;

export const MIN_GROUP_PASSENGERS_COUNT = 16; // infants are not passengers, they are more like baggages
export const MAX_PASSENGERS_COUNT = 180; // infants are not passengers, they are more like baggages
export const MAX_INFANT_COUNT = 9;

export const MINIMUM_PASSENGER_COUNT_FOR_PER_PERSON_VISIBILITY = 2;

export const MIN_INTERVAL_BEFORE_RETURN_FLIGHT = 90; // in minutes

export const CHECKIN_WAY_TOO_EARLY_DAYS = 30;

export const LANGUAGE_CODE_EN_GB = 'en-gb';

export const FALLBACK_LOCALE_CODE = LANGUAGE_CODE_EN_GB;
export const FALLBACK_CURRENCY_CODE = 'EUR';

export const ROUNDED_CURRENCIES = [
  'AED',
  'CZK',
  'DKK',
  'GEL',
  'HRK',
  'HUF',
  'ILS',
  'MKD',
  'NOK',
  'PLN',
  'RON',
  'RSD',
  'SEK',
  'UAH',
  'ALL',
];

export const STRICTLY_ROUNDED_CURRENCIES = ['ALL'];

export const COUNTRY_CODE_GB = 'gb';
export const COUNTRY_CODE_HU = 'hu';
export const COUNTRY_CODE_ITALY = 'it';
export const COUNTRY_CODE_POLAND = 'pl';

export const LANGUAGE_CODE_GEORGIAN = 'ka-ge';

export const GOOGLE_RECAPTCHA_SITEKEY = isServer() ? '' : window.wizz.reCaptchaSiteKey;

export const INFORMATION_PAGE_ID = 'information';

// yo dawg, heard you like human readable error messages AND constants
export const VALIDATION_CODE_BOOKING_IN_PROGRESS =
  'AgenciesNotAllowedToLoginWhileBookingFlowIsAlreadyInProgress';
export const VALIDATION_CODE_FARE_LOCK_NOT_ALLOWED = 'FareLockNotAllowed';
export const VALIDATION_CODE_FORCE_PASSWORD_CHANGE = 'ForcePasswordChange';
export const VALIDATION_CODE_NEED_EMAIL_VERIFICATION = 'NeedEmailVerification';
export const VALIDATION_CODE_INVALID_CAPTCHA = 'InvalidCaptcha';
export const VALIDATION_CODE_CAPTCHA_PLEASE_SOLVE = 'CaptchaPleaseSolve';
export const VALIDATION_CODE_CAPTCHA_REQUIRED = 'CaptchaRequired';
export const VALIDATION_CODE_SESSION_MISMATCH = 'SessionMismatch';
export const VALIDATION_CODE_SESSION_TIMEOUT = 'SessionTimedOut';
export const VALIDATION_CODE_LOGGED_IN_USER_FOUND = 'LoggedInUserFound';
export const VALIDATION_CODE_DEFINE_FIRST_NAME =
  'MoreThanOnePassengerFoundDefineFirstName';
export const VALIDATION_CODE_INVALID_BOOKING_STATE = 'InvalidBookingState';
export const VALIDATION_CODE_BOOKING_WAS_MODIFIED_BY_ANOTHER_USER =
  'BookingWasModifyByAnotherUser';
export const VALIDATION_CODE_PAYMENT_DECLINED = 'PaymentDeclined';
export const VALIDATION_CODE_PENDING_PAYMENT_ERROR_TO_ITINERARY =
  'PendingPaymentErrorToItinerary';
export const VALIDATION_CODE_FLIGHT_CHANGE_IN_PROGRESS =
  'BookingIsInFlightchangeProcessAlready';
export const VALIDATION_CODE_UNEXPECTED_PRIVILEGE_PASS_EXCEPTION =
  'UnexpectedPrivilegeException';
// note the value is different from the server because of security reasons
export const SERVER_ERROR_INVALID_CSRF_TOKEN = 'InvalidProtocol';

export const DEFAULT_ERROR_LABEL = 'CriticalError';

export const STATE_SELECTED = 'js-selected';
export const STATE_SELECTABLE = 'js-selectable';
export const STATE_INTEREST = 'js-interest'; // point of interest
export const STATE_ACTIVE = 'active';

export const ACCESSIBILITY_ENABLED = 'enabled';
export const ACCESSIBILITY_ENABLED_READ_ONLY = 'enabledReadOnly';
export const ACCESSIBILITY_DISABLED = 'disabled';
export const ACCESSIBILITY_READONLY = 'readonly';
export const ACCESSIBILITY_HIDDEN = 'hidden';
export const ACCESSIBILITY_REQUIRED = 'required';
export const ACCESSIBILITY_NOT_AVAILABLE = 'notAvailable';
export const ACCESSIBILITY_SOLD_OUT = 'soldOut';
export const ACCESSIBILITY_NOT_INITIALIZED = 'notInitialized';

export const CALENDAR_MAX_YEAR_RANGE = 2;

// Agency booking
export const CUSTOMER_TYPE = Object.freeze({
  REGULAR: 'regular',
  AGENCY: 'agency',
});

export const AVAILABLE_BILLING_OPTIONS = Object.freeze({
  COMPANY: 'company',
  PRIVATE: 'private',
});

// Booking flow steps
export const SUBSTEP_NOTHING = 'substep-nothing';
export const SUBSTEP_FAILED = 'substep-failed';
export const STEP_NOTHING = 'nothing';
export const STEP_SELECT_FLIGHT = 'select-flight';
export const STEP_PASSENGERS = 'passengers';
export const STEP_SEAT_SELECTION = 'seat-selection';
export const STEP_SERVICES = 'services';
export const STEP_WDC = 'wdc';
export const STEP_PAYMENT = 'payment';
export const STEP_SUCCESSFUL_PAYMENT = 'successful-payment';
export const STEP_FLIGHT_CHANGE = 'flight-change';
export const STEP_CONNECTED_FLIGHTS = 'mix-and-match';
export const WDC_MODAL_POPUP = 'wdc-modal-popup';
export const WDC_RETARGETING_POPUP = 'wdc-retargeting-popup';

export const ANCILLARY_RECOMMENDATION = 'ancillary-recommendation';
export const SEAT_RECOMMENDATION = 'seat-recommendation';

export const BASIC_BOOKING_FLOW_STEPS = [
  STEP_SELECT_FLIGHT,
  STEP_PASSENGERS,
  STEP_SEAT_SELECTION,
  STEP_SERVICES,
  STEP_WDC,
  STEP_PAYMENT,
];

export const BASIC_FARE_LOCK_FLOW_STEPS = [
  FARE_LOCK_FLOW_SELECTED_FLIGHTS,
  STEP_PASSENGERS,
  STEP_SEAT_SELECTION,
  STEP_SERVICES,
  STEP_PAYMENT,
];

export const TRACKING_LOCATION_ITINERARY = 'itinerary';
export const TRACKING_LOCATION_ITINERARY_CHECK_IN = 'itinerary-check-in';
export const TRACKING_LOCATION_SUCCESSFUL_BOOKING = 'successful booking';
export const TRACKING_LOCATION_FLIGHT_SELECT_UPSELL = 'select-flight-upsell';

// Password strength
export const PASSWORD_STRENGTH_LOW_NAME = 'low';
export const PASSWORD_STRENGTH_GOOD_NAME = 'good';
export const PASSWORD_STRENGTH_MAX_LEVEL = 5;
export const PASSWORD_STRENGTH_STRONG_MIN_PERCENT = 60;

// Date of birth
export const MAX_AGE_FOR_INFANT = 2;
export const MIN_AGE_FOR_CHILD = 2;
export const MAX_AGE_FOR_CHILD = 14;
export const MAX_AGE_FOR_ADULT = 110;
export const MIN_AGE_FOR_CHILD_GENDER = 12;

// Genders
export const GENDER_MALE = 'male';
export const GENDER_FEMALE = 'female';
export const GENDER_UNKNOWN = 'unknown';

// Colors
export const COLOR_WHITE = 'white';
export const COLOR_GRAY_SEMILIGHT = 'gray-semilight';
export const COLOR_GRAY_DARK = 'gray-dark';
export const COLOR_PINK = 'pink';
export const COLOR_BLUE = 'blue';
export const COLOR_GREEN = 'green';
export const COLOR_AZURE = 'azure';
export const COLOR_AZURE_FILL = '#00A1E0';
export const COLOR_PRIMARY = '#C6007E';
export const COLOR_SECONDARY = '#06038d';
export const COLOR_CONTENT_SECONDARY = '#999999';
export const COLOR_CONTENT_DISABLED = '#c1c1c1';
export const COLOR_CONTENT_PRIMARY = '#343434';
export const COLOR_ACCENT_SUCCESS = '#277C5A';

// Bundles
export const BUNDLE_BASIC = 'basic';
export const BUNDLE_MIDDLE = 'middle';
export const BUNDLE_MIDDLE_TWO = 'middletwo';
export const BUNDLE_SMART = 'smart';
export const BUNDLE_PLUS = 'plus';

export const BUNDLES = [BUNDLE_BASIC, BUNDLE_MIDDLE, BUNDLE_PLUS];
export const NUMBER_OF_BUNDLES = BUNDLES.length;

export const NEW_BUNDLE_COLORS = new Map();
NEW_BUNDLE_COLORS.set(BUNDLE_BASIC, COLOR_GRAY_DARK);
NEW_BUNDLE_COLORS.set(BUNDLE_SMART, COLOR_AZURE);
NEW_BUNDLE_COLORS.set(BUNDLE_MIDDLE, COLOR_BLUE);
NEW_BUNDLE_COLORS.set(BUNDLE_MIDDLE_TWO, COLOR_BLUE);
NEW_BUNDLE_COLORS.set(BUNDLE_PLUS, COLOR_PINK);

export const BUNDLE_COLORS = new Map();
BUNDLE_COLORS.set(BUNDLE_BASIC, COLOR_BLUE);
BUNDLE_COLORS.set(BUNDLE_MIDDLE, COLOR_BLUE);
BUNDLE_COLORS.set(BUNDLE_PLUS, COLOR_PINK);

export const BUNDLE_COLORS_SECONDARY = new Map();
BUNDLE_COLORS_SECONDARY.set(BUNDLE_BASIC, COLOR_BLUE);
BUNDLE_COLORS_SECONDARY.set(BUNDLE_MIDDLE, COLOR_WHITE);
BUNDLE_COLORS_SECONDARY.set(BUNDLE_PLUS, COLOR_WHITE);

// Seat assignment results
export const SEAT_ASSIGNMENT_RESULT_SUCCESS = 'success';
export const SEAT_ASSIGNMENT_RESULT_CHILD_RULE_VIOLATION = 'childRuleViolation';
export const SEAT_ASSIGNMENT_RESULT_SERVICE_UNAVAILABLE = 'serviceUnavailable';
export const SEAT_ASSIGNMENT_RESULT_NO_SEAT_AVAILABLE = 'noSeatAvailable';
export const SEAT_ASSIGNMENT_RESULT_BLOCKED_BY_SEATING_TOGETHER =
  'blockedBySeatingTogetherGuarantee';

// Seat assignment 400 error codes
export const SEAT_ASSIGNMENT_ERROR_400_SELECTED_OUTBOUND_SEAT_NOT_AVAILABLE =
  'SelectedOutboundSeatNotAvailable';
export const SEAT_ASSIGNMENT_ERROR_400_SELECTED_RETURN_SEAT_NOT_AVAILABLE =
  'SelectedReturnSeatNotAvailable';
export const SEAT_ASSIGNMENT_ERROR_400_INVALID_OUTBOUND_SEAT_REQUEST =
  'InvalidOutboundSeatRequest';
export const SEAT_ASSIGNMENT_ERROR_400_INVALID_RETURN_SEAT_REQUEST =
  'InvalidReturnSeatRequest';
export const SEAT_ASSIGNMENT_ERROR_400_NOT_AUTHORIZED_FOR_THE_ACTION =
  'NotAuthorizedForTheAction';
export const SEAT_ASSIGNMENT_ERROR_400_SEAT_CANNOT_BE_MODIFIED_DURING_FARE_LOCKING =
  'SeatCannotBeModifiedDuringFareLocking';
export const SEAT_ASSIGNMENT_ERROR_400_SEAT_RESET_ONLY_AVAILABLE_IN_ADD_SERVICES_FLOW =
  'SeatResetOnlyAvailableInAddServicesFlow';

// Flow types
export const FLOW_TYPE_NEW_BOOKING = 'new-booking';
export const FLOW_TYPE_ADD_SERVICES = 'add-services';
export const FLOW_TYPE_CHANGE_FLIGHT = 'change-flight';
export const FLOW_TYPE_CHANGE_NAME = 'change-name';
export const FLOW_TYPE_ADD_ANCILLARIES = 'add-ancillaries';
export const FLOW_TYPE_REBOOK = 'rebook';
export const FLOW_TYPE_GROUP_BOOKING = 'group-booking';
export const FLOW_TYPE_CHECK_IN = 'check-in';
export const FLOW_TYPE_FARE_LOCK_FINALIZE = 'fare-lock-finalize';
export const FLOW_TYPE_CONNECTED_BOOKING = 'connected-booking';

// Passenger types
export const ADULT = 'adult';
export const CHILD = 'child';
export const INFANT = 'infant';

export const BACKEND_PASSENGER_TYPES = {
  [ADULT]: 'ADT',
  [CHILD]: 'CHD',
  [INFANT]: 'INFT',
};

// Price types
export const PRICE_TYPE_REGULAR = 'regular';
export const PRICE_TYPE_WDC = 'wdc';

// Search
export const DEPARTURE_MODEL = 'departureStation';
export const ARRIVAL_MODEL = 'arrivalStation';

export const DEPARTURE_ID = 'departure-station-field';
export const ARRIVAL_ID = 'arrival-station-field';

export const NUMBER_OF_CLOSEST_STATIONS = 3;

// none, percentage, fixedAmount
export const FARE_DISCOUNT_TYPE_NONE = 'none';
export const FARE_DISCOUNT_TYPE_PERCENTAGE = 'percentage';
export const FARE_DISCOUNT_TYPE_FIXED_AMOUNT = 'fixedAmount';

export const FLIGHT_DATES_INTERVAL = 62;

export const BOOKING_COM_REDIRECT_URL_KEY = 'booking-com-redirect-url';
export const BOOKING_COM_REDIRECT_FLAG = 'booking-com';

export const BOOKING_COM_URL = 'bookingComUrl';
export const BOOKING_COM_POPUNDER_URL = 'bookingComPopunderUrl';

export const CAR_RENTAL_URL = 'carRentalUrl';
export const CAR_RENTAL_POPUNDER_URL = 'carRentalPopunderUrl';
export const E_SIM_DATA_ROAMING_URL = 'eSimDataRoamingUrl';
export const CAR_RENTAL_PAYMENT_STATUS_SUCCESS = 'success';

// Fare chart
export const FARE_CHART_DAY_INTERVAL = 7;
export const FARE_CHART_DAY_WIDTH = 86;
export const PRICE_TYPE_PRICE = 'price';
export const PRICE_TYPE_CHECK_PRICE = 'checkPrice';
export const PRICE_TYPE_NO_DATA = 'noData';
export const PRICE_TYPE_SOLD_OUT = 'soldOut';

// Ancillary Baggages
export const ANCILLARY_EXTRA = 'extra';
export const ANCILLARY_LIGHT = 'light';
export const ANCILLARY_MEDIUM = 'medium';
export const ANCILLARY_HEAVY = 'heavy';
export const ANCILLARY_EXTRALIGHTONE = 'extralightone';
export const ANCILLARY_ULTRALIGHTONE = 'ultralightone';
export const ANCILLARY_ULTRALIGHTTWO = 'ultralighttwo';
export const ANCILLARY_ULTRALIGHTTHREE = 'ultralightthree';
export const ANCILLARY_ULTRALIGHTFOUR = 'ultralightfour';
export const ANCILLARY_ULTRALIGHTFIVE = 'ultralightfive';
export const ANCILLARY_ULTRALIGHTSIX = 'ultralightsix';

export const ANCILLARY_MEDIUMONE = 'mediumone';
export const ANCILLARY_MEDIUMTWO = 'mediumtwo';
export const ANCILLARY_MEDIUMTHREE = 'mediumthree';
export const ANCILLARY_MEDIUMFOUR = 'mediumfour';
export const ANCILLARY_MEDIUMFIVE = 'mediumfive';
export const ANCILLARY_MEDIUMSIX = 'mediumsix';

export const ANCILLARY_HEAVYONE = 'heavyone';
export const ANCILLARY_HEAVYTWO = 'heavytwo';
export const ANCILLARY_HEAVYTHREE = 'heavythree';
export const ANCILLARY_HEAVYFOUR = 'heavyfour';
export const ANCILLARY_HEAVYFIVE = 'heavyfive';
export const ANCILLARY_HEAVYSIX = 'heavysix';

export const BAGGAGE_LIGHT = ['lightone', 'lighttwo'];
export const BAGGAGE_LIGHT_WEIGHT = 23;
export const BAGGAGE_EXTRA_LIGHT = [ANCILLARY_EXTRALIGHTONE];
export const BAGGAGE_EXTRA_LIGHT_WEIGHT = 10;
export const BAGGAGE_ULTRA_LIGHT = [
  ANCILLARY_ULTRALIGHTONE,
  ANCILLARY_ULTRALIGHTTWO,
  ANCILLARY_ULTRALIGHTTHREE,
  ANCILLARY_ULTRALIGHTFOUR,
  ANCILLARY_ULTRALIGHTFIVE,
  ANCILLARY_ULTRALIGHTSIX,
];
export const BAGGAGE_ULTRA_LIGHT_WEIGHT = 20;

export const BAGGAGE_MEDIUM = [
  ANCILLARY_MEDIUMONE,
  ANCILLARY_MEDIUMTWO,
  ANCILLARY_MEDIUMTHREE,
  ANCILLARY_MEDIUMFOUR,
  ANCILLARY_MEDIUMFIVE,
  ANCILLARY_MEDIUMSIX,
];
export const BAGGAGE_MEDIUM_WEIGHT = 26;

export const BAGGAGE_HEAVY = [
  ANCILLARY_HEAVYONE,
  ANCILLARY_HEAVYTWO,
  ANCILLARY_HEAVYTHREE,
  ANCILLARY_HEAVYFOUR,
  ANCILLARY_HEAVYFIVE,
  ANCILLARY_HEAVYSIX,
];
export const BAGGAGE_HEAVY_WEIGHT = 32;
export const BAGGAGE_NONE = 'none';

export const BAGGAGE_WEIGHT_BY_KEY_MAP = new Map()
  .set(ANCILLARY_EXTRA, { weight: BAGGAGE_EXTRA_LIGHT_WEIGHT })
  .set(ANCILLARY_LIGHT, { weight: BAGGAGE_ULTRA_LIGHT_WEIGHT })
  .set(ANCILLARY_MEDIUM, { weight: BAGGAGE_MEDIUM_WEIGHT })
  .set(ANCILLARY_HEAVY, { weight: BAGGAGE_HEAVY_WEIGHT });

export const BAGGAGE_OPTION_TO_COUNT_AND_WEIGHT_MAP = new Map()
  .set('none', {
    count: 0,
    weight: 'none',
    weightInKg: 0,
  })
  .set(ANCILLARY_EXTRALIGHTONE, {
    count: 1,
    weight: 'extra',
    weightInKg: BAGGAGE_EXTRA_LIGHT_WEIGHT,
  })
  .set('lightone', {
    count: 1,
    weight: 'light',
    weightInKg: BAGGAGE_LIGHT_WEIGHT,
  })
  .set('lighttwo', {
    count: 2,
    weight: 'light',
    weightInKg: BAGGAGE_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTONE, {
    count: 1,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTTWO, {
    count: 2,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTTHREE, {
    count: 3,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTFOUR, {
    count: 4,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTFIVE, {
    count: 5,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })
  .set(ANCILLARY_ULTRALIGHTSIX, {
    count: 6,
    weight: 'ultralight',
    weightInKg: BAGGAGE_ULTRA_LIGHT_WEIGHT,
  })

  .set(ANCILLARY_MEDIUMONE, {
    count: 1,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })
  .set(ANCILLARY_MEDIUMTWO, {
    count: 2,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })
  .set(ANCILLARY_MEDIUMTHREE, {
    count: 3,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })
  .set(ANCILLARY_MEDIUMFOUR, {
    count: 4,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })
  .set(ANCILLARY_MEDIUMFIVE, {
    count: 5,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })
  .set(ANCILLARY_MEDIUMSIX, {
    count: 6,
    weight: 'medium',
    weightInKg: BAGGAGE_MEDIUM_WEIGHT,
  })

  .set(ANCILLARY_HEAVYONE, {
    count: 1,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  })
  .set(ANCILLARY_HEAVYTWO, {
    count: 2,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  })
  .set(ANCILLARY_HEAVYTHREE, {
    count: 3,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  })
  .set(ANCILLARY_HEAVYFOUR, {
    count: 4,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  })
  .set(ANCILLARY_HEAVYFIVE, {
    count: 5,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  })
  .set(ANCILLARY_HEAVYSIX, {
    count: 6,
    weight: 'heavy',
    weightInKg: BAGGAGE_HEAVY_WEIGHT,
  });

// matching max-width breakpoints from _variables.scss
export const BREAKPOINT_DESKTOP = 1400;
export const BREAKPOINT_SMALL_DESKTOP = 1200;
export const BREAKPOINT_TABLET = 1000;
export const BREAKPOINT_SMALL_TABLET = 850;
export const BREAKPOINT_PHABLET = 701;
export const BREAKPOINT_MOBILE = 500;

export const DESKTOP_DEVICE = 'desktop';
export const MOBILE_DEVICE = 'mobile';

export const BREAKPOINTS = new Map();
BREAKPOINTS.set('desktop', BREAKPOINT_DESKTOP);
BREAKPOINTS.set('small-desktop', BREAKPOINT_SMALL_DESKTOP);
BREAKPOINTS.set('tablet', BREAKPOINT_TABLET);
BREAKPOINTS.set('small-tablet', BREAKPOINT_SMALL_TABLET);
BREAKPOINTS.set('phablet', BREAKPOINT_PHABLET);
BREAKPOINTS.set('mobile', BREAKPOINT_MOBILE);

// Privilege Pass
export const PRIVILEGE_PASS_SERVICES = ['priorityBoarding'];

// Seatmap
export const PLANETYPE_A320 = '320';
export const PLANETYPE_A321 = '321';
export const PLANETYPE_A321NEO = '321Neo';
export const PLANETYPE_A321NEO_SUBTYPE = '239';
export const PLANETYPE_NEO_SUBTYPE = 'Neo';

export const PLANETYPE_A320_FRONT_DOOR_THRESHOLD = 15;
export const PLANETYPE_A321_FRONT_DOOR_THRESHOLD = 20;
export const CARRIER_CODE = 'W6';
export const OPERATOR_SUFFIX = ' ';

// WDC
export const WDC_MEMBERSHIP_COBRANDED = 'WDC_COBRANDED';
export const WDC_MEMBERSHIP_STANDARD = 'WDC_STANDARD';
export const WDC_MEMBERSHIP_STANDARD_PLUS = 'WDC_STANDARD_PLUS';
export const WDC_MEMBERSHIP_PREMIUM = 'WDC_PREMIUM';
export const WDC_MEMBERSHIP_PREMIUM_PLUS = 'WDC_PREMIUM_PLUS';
export const WDC_MEMBERSHIP_COBRANDED_STANDARD = 'WDC_COBRANDED_STANDARD';
export const WDC_MEMBERSHIP_GROUP = 'WDC_GROUP';
export const WDDC_MEMBERSHIP_STANDARD_ITALY = 'WDDC_STANDARD_ITALY';
export const WDDC_MEMBERSHIP_GROUP_ITALY = 'WDDC_GROUP_ITALY';
export const WDC_MEMBERSHIP_TYPE_STANDARD = 'STANDARD';
export const WDC_MEMBERSHIP_TYPE_GROUP = 'GROUP';
export const WDC_MAX_PASSENGER_COUNT_STANDARD = 2;
export const WDC_MAX_PASSENGER_COUNT_GROUP = 6;
export const WDC_MAX_PASSENGER_COUNT_STANDARD_AND_PREMIUM = 2;
export const WDC_MEMBERSHIP = 'WDC membership';
export const DC_MEMBERSHIP = 'DC membership';

export const WDC_MEMBERSHIP_UPGRADE = 'Upgrade';

// WIZZ DOMESTIC DISCOUNT CLUB
export const WIZZ_DISCOUNT_CLUB_ITALY = 'Italy Discount Club';

export const WIZZ_DOMESTIC_DISCOUNT_CLUB_COUNTRIES = new Map();
WIZZ_DOMESTIC_DISCOUNT_CLUB_COUNTRIES.set('IT', WIZZ_DISCOUNT_CLUB_ITALY);

export const WIZZ_DOMESTIC_DISCOUNT_CLUB_READ_MORE_URLS = new Map();

WIZZ_DOMESTIC_DISCOUNT_CLUB_READ_MORE_URLS.set(
  WIZZ_DISCOUNT_CLUB_ITALY,
  'discountClubItaly'
);

export const WIZZ_DOMESTIC_DISCOUNT_CLUB_ITALY_URL = 'discountClubItaly';
export const WIZZ_WDC_MORE_INFO_URL = 'wdc';

export const WIZZ_DOMESTIC_DISCOUNT_CLUB_ICONS = new Map();
WIZZ_DOMESTIC_DISCOUNT_CLUB_ICONS.set(WIZZ_DISCOUNT_CLUB_ITALY, 'wddc-italy');

export const WIZZ_DOMESTIC_DISCOUNT_CLUB_ADDED_ICONS = new Map();

WIZZ_DOMESTIC_DISCOUNT_CLUB_ADDED_ICONS.set(WIZZ_DISCOUNT_CLUB_ITALY, 'wddc-added-italy');

export const WDC_MEMBERSHIP_NAME_MAP = new Map()
  .set(WDC_MEMBERSHIP_COBRANDED, 'Group')
  .set(WDC_MEMBERSHIP_STANDARD, 'Standard')
  .set(WDC_MEMBERSHIP_STANDARD_PLUS, 'Standard')
  .set(WDC_MEMBERSHIP_COBRANDED_STANDARD, 'Standard')
  .set(WDC_MEMBERSHIP_PREMIUM, 'Premium')
  .set(WDC_MEMBERSHIP_PREMIUM_PLUS, 'Premium')
  .set(WDC_MEMBERSHIP_GROUP, 'Group')
  .set(WDDC_MEMBERSHIP_STANDARD_ITALY, 'Standard')
  .set(WDDC_MEMBERSHIP_GROUP_ITALY, 'Group');

export const WDC_MEMBERSHIP_NAME_MAP_REDESIGNED = new Map()
  .set(WDC_MEMBERSHIP_STANDARD, 'Standard')
  .set(WDC_MEMBERSHIP_COBRANDED_STANDARD, 'Standard')
  .set(WDC_MEMBERSHIP_GROUP, 'Standard Plus')
  .set(WDC_MEMBERSHIP_COBRANDED, 'Standard Plus')
  .set(WDC_MEMBERSHIP_PREMIUM, 'Premium')
  .set(WDC_MEMBERSHIP_PREMIUM_PLUS, 'Premium Plus');

export const WDC_MEMBERSHIP_OUTLINED_ICONS = new Map()
  .set(WDC_MEMBERSHIP_STANDARD, 'standard')
  .set(WDC_MEMBERSHIP_COBRANDED_STANDARD, 'standard')
  .set(WDC_MEMBERSHIP_GROUP, 'standard-plus')
  .set(WDC_MEMBERSHIP_COBRANDED, 'standard-plus')
  .set(WDC_MEMBERSHIP_PREMIUM, 'premium')
  .set(WDC_MEMBERSHIP_PREMIUM_PLUS, 'premium-plus');

export const WDC_MEMBERSHIP_STANDARD_CODE = 'CLUBPR';
export const WDC_MEMBERSHIP_GROUP_CODE = 'CLUBGP';
export const WDC_MEMBERSHIP_PREMUM_CODE = 'CLUBPP';
export const WDC_MEMBERSHIP_PREMUM_PLUS_CODE = 'CLUBPL';

export const WDC_MEMBERSHIP_CODE_MAP = new Map()
  .set(WDC_MEMBERSHIP_STANDARD, WDC_MEMBERSHIP_STANDARD_CODE)
  .set(WDC_MEMBERSHIP_COBRANDED_STANDARD, WDC_MEMBERSHIP_STANDARD_CODE)
  .set(WDC_MEMBERSHIP_GROUP, WDC_MEMBERSHIP_GROUP_CODE)
  .set(WDC_MEMBERSHIP_COBRANDED, WDC_MEMBERSHIP_GROUP_CODE)
  .set(WDC_MEMBERSHIP_PREMIUM, WDC_MEMBERSHIP_PREMUM_CODE)
  .set(WDC_MEMBERSHIP_PREMIUM_PLUS, WDC_MEMBERSHIP_PREMUM_PLUS_CODE);

export const WDC_MEMBERSHIP_PREMIUM_CODE = 'CLUBPP';
export const WDC_MEMBERSHIP_PREMIUM_PLUS_CODE = 'CLUBPL';

export const WDC_MEMBERSHIP_STANDARD_DOMESTIC_ITALY_CODE = 'DCIST';
export const WDC_MEMBERSHIP_GROUP_DOMESTIC_ITALY_CODE = 'DCIGR';

export const WDC_ITALY_DISCOUNT_CLUB = 'italy-discount-club';

export const WDC_MEMBERSHIP_LIMIT_MAP = new Map();
WDC_MEMBERSHIP_LIMIT_MAP.set(WDC_MEMBERSHIP_STANDARD, WDC_MAX_PASSENGER_COUNT_STANDARD);
WDC_MEMBERSHIP_LIMIT_MAP.set(WDC_MEMBERSHIP_GROUP, WDC_MAX_PASSENGER_COUNT_GROUP);

export const WDC_MEMBERSHIP_TYPE_NEW_LIGHT = 'light';
export const WDC_MEMBERSHIP_TYPE_NEW_STANDARD = 'standard';
export const WDC_MEMBERSHIP_TYPE_NEW_GROUP = 'group';
export const WDC_MEMBERSHIP_TYPE_PREMIUM = 'premium';
export const WDC_MEMBERSHIP_TYPE_PREMIUM_PLUS = 'premium-plus';

// Payment
export const CREDIT_CARD_TYPE_CODE_VISA = 'VI';
export const CREDIT_CARD_TYPE_CODE_WIZZ_HU_MASTERCARD = 'MW';
export const CREDIT_CARD_TYPE_CODE_WIZZ_RO_MASTERCARD = 'MV';
export const CREDIT_CARD_TYPE_CODE_WIZZ_PL_MASTERCARD = 'MP';
export const CREDIT_CARD_TYPE_CODE_MASTERCARD = 'MC';
export const CREDIT_CARD_TYPE_CODE_MAESTRO = 'MA';
export const CREDIT_CARD_TYPE_CODE_BANCONTACT = 'BC';
export const CREDIT_CARD_TYPE_CODE_UATP = 'TP';
export const CREDIT_CARD_TYPE_CODE_DINERS = 'DN';
export const CREDIT_CARD_TYPE_CODE_DISCOVER = 'DS';

export const CREDIT_CARD_NAMES_BY_CODE = new Map();
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_VISA, 'Visa');
CREDIT_CARD_NAMES_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_HU_MASTERCARD,
  'Wizz HU MasterCard'
);
CREDIT_CARD_NAMES_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_RO_MASTERCARD,
  'Wizz RO MasterCard'
);
CREDIT_CARD_NAMES_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_PL_MASTERCARD,
  'Wizz PL MasterCard'
);
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_MASTERCARD, 'MasterCard');
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_MAESTRO, 'Maestro');
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_BANCONTACT, 'Bancontact');
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_UATP, 'UATP');
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_DINERS, 'Diners');
CREDIT_CARD_NAMES_BY_CODE.set(CREDIT_CARD_TYPE_CODE_DISCOVER, 'Discover');

export const CREDIT_CARD_LABELS_BY_CODE = new Map();
CREDIT_CARD_LABELS_BY_CODE.set(CREDIT_CARD_TYPE_CODE_VISA, 'Visa');
CREDIT_CARD_LABELS_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_HU_MASTERCARD,
  'WIZZ Card Hungary'
);
CREDIT_CARD_LABELS_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_RO_MASTERCARD,
  'WIZZ Card Romania'
);
CREDIT_CARD_LABELS_BY_CODE.set(
  CREDIT_CARD_TYPE_CODE_WIZZ_PL_MASTERCARD,
  'WIZZ Card Poland'
);
CREDIT_CARD_LABELS_BY_CODE.set(CREDIT_CARD_TYPE_CODE_MASTERCARD, 'MasterCard');
CREDIT_CARD_LABELS_BY_CODE.set(CREDIT_CARD_TYPE_CODE_MAESTRO, 'Maestro');
CREDIT_CARD_LABELS_BY_CODE.set(CREDIT_CARD_TYPE_CODE_BANCONTACT, 'Bancontact');
CREDIT_CARD_LABELS_BY_CODE.set(CREDIT_CARD_TYPE_CODE_UATP, 'UATP');

export const CO_BRANDED_CREDIT_CARD_TYPE_CODES = [
  CREDIT_CARD_TYPE_CODE_WIZZ_RO_MASTERCARD,
  CREDIT_CARD_TYPE_CODE_WIZZ_HU_MASTERCARD,
  CREDIT_CARD_TYPE_CODE_WIZZ_PL_MASTERCARD,
];

// TFS 2183
export const CREDIT_CARD_LIST_ORDER = [
  CREDIT_CARD_TYPE_CODE_MAESTRO,
  CREDIT_CARD_TYPE_CODE_MASTERCARD,
  CREDIT_CARD_TYPE_CODE_VISA,
  CREDIT_CARD_TYPE_CODE_UATP,
  CREDIT_CARD_TYPE_CODE_WIZZ_RO_MASTERCARD,
  CREDIT_CARD_TYPE_CODE_WIZZ_HU_MASTERCARD,
];

export const UNACCEPTED_CARDS = [
  CREDIT_CARD_TYPE_CODE_DINERS,
  CREDIT_CARD_TYPE_CODE_DISCOVER,
  CREDIT_CARD_TYPE_CODE_BANCONTACT,
];
export const CREDIT_CARD_AVAILABLE_EXPIRY_YEARS = 17;

// Summary
export const PAID_AT_THIRD_PARTY = ['carRental', 'fullProtection'];

// Itinerary statuses
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_HOLD = 'hold';
export const STATUS_FARE_LOCKED = 'fareLocked';
export const STATUS_FLOWN = 'flown';
export const STATUS_CANCELED = 'canceled';
export const STATUS_CANCEL_IN_PROGRESS = 'divideCancellationInProgress';
export const STATUS_FLIGHT_CHANGE_IN_PROGRESS = 'divideFlightChangeInProgress';
export const STATUS_FARE_LOCK_FINALIZE = 'fareLockFinalize';
export const STATUS_PENDING_PAYMENT = 'pendingPayment';
export const STATUS_WAIT = 'wait';
export const STATUS_WDC_CUT_IN_PROGRESS = 'wdcMembershipCutInProgress';
export const STATUS_WDC_CUT_ERROR = 'wdcMembershipCutError';

// Itinerary props
export const PROP_FLIGHT_CHECK_IN_STATUS = 'flightCheckinStatus';

// Itinerary operations
export const OPERATION_ADD_BAGGAGES = 'addBaggages';
export const OPERATION_ADD_SERVICE = 'addService';
export const OPERATION_CHECK_IN = 'checkin';
export const OPERATION_CHANGE_SEAT = 'changeSeat';
export const OPERATION_FLIGHT_CHANGE = 'flightChange';
export const OPERATION_DIVIDE_FLIGHT_CHANGE = 'divideFlightChange';
export const OPERATION_NAME_CHANGE = 'nameChange';
export const OPERATION_REBOOK_FLIGHT = 'rebookFlight';
export const OPERATION_ACCEPT_NEW_FLIGHT = 'acceptNewFlightDateTime';
export const OPERATION_REBOOK_REFUND = 'rebookRefund';
export const OPERATION_FARE_LOCK_FINALIZE = 'fareLockFinalize';
export const OPERATION_FLIGHT_COMPENSATION = 'flightCompensation';
export const OPERATION_GROUP_BOOKING_NAME_CHANGE = 'groupBookingNameChange';
export const OPERATION_LIST_INVOICES = 'listInvoices';
export const OPERATION_ACCEPT_NEW_GCC = 'gccAcceptance';
export const OPERATION_GCC_REFUND = 'gccRefund';
export const OPERATION_BLOCKED_BY_GCC = 'blockedByGcc';
export const OPERATION_GCC_CALL_CENTER_REFUND = 'callCenterRefund';
export const OPERATION_SHOW_PRICE_DETAILS = 'showPriceDetails';
export const OPERATION_CHANGE_MOBILE_PHONE = 'changeMobilePhone';
export const OPERATION_UPDATE_MOBILE_PHONE_ON_PROFILE = 'saveMobilePhoneToProfile';
export const OPERATION_AIRCRAFT_TYPE_CHANGED = 'aircraftTypeChanged';
export const OPERATION_AIRCRAFT_TYPE_CHANGED_SEAT_REFUND = 'aircraftTypeChangeSeatRefund';
export const OPERATION_BUNDLE_UPSELL = 'bundleUpsell';

// Itinerary third-party error response codes
export const THIRD_PARTY_ERROR_CODES = {
  acordInsurance: 'ACORDInsurance',
  airportParking: 'AirportParking',
  airportTransfer: 'AirportTransfer',
  airportTransferV2: 'AirportTransferV2',
  insurance: 'Insurance',
  carRental: 'CarRental',
  carRentalSixt: 'CarRentalSixt',
  carTrawler: 'CarTrawler',
  fastTrackSecurityV2: 'FastTrackSecurityV2',
  loungeAccessV2: 'LoungeAccessV2',
};

// Boarding card formats
export const BOARDING_CARD_FORMAT_HTML = 'HTML';
export const BOARDING_CARD_FORMAT_PDF = 'PDF';
export const BOARDING_CARD_FORMAT_PASSBOOK = 'PASSBOOK';
export const BOARDING_CARD_AVAILABLE_UNTIL_HOURS = 2;

// Check-in
export const CHECK_IN_AVAILABLE_UNTIL_HOURS = 3;
export const CHECK_IN_STATUS_CHECKED_IN = 'checkedin';
export const CHECK_IN_STATUS_NOT_CHECKED_IN = 'notcheckedin';

// Document types
export const DOCUMENT_TYPE_PASSPORT = 'passport';
export const DOCUMENT_TYPE_NATIONAL_ID = 'nationalId';

// Check in bag types
export const CHECK_IN_BAG_TYPES = [
  'eBag1',
  'bag1',
  'bag2',
  'bag3',
  'bag4',
  'bag5',
  'bag6',
  'uBag1',
  'uBag2',
  'uBag3',
  'uBag4',
  'uBag5',
  'uBag6',
  'mBag1',
  'mBag2',
  'mBag3',
  'mBag4',
  'mBag5',
  'mBag6',
  'lBag1',
  'lBag2',
];

// Service types
export const EXCLUDED_PASSENGER_SERVICES = [
  'transfer', // transfer is an exception, it is handled elsewhere
  'administration',
  'cabinBagSmall',
  'cabinBagLarge',
  ...CHECK_IN_BAG_TYPES,
  'airportCheckin',
  'delayedWarranty',
  'fastTrackSecurity',
  'loungeAccess',
  'priorityBoarding',
  'seatPrice',
  'sportsEquipment',
  'wizzFlex',
  'seatingTogetherGuarantee',
];

export const SERVICE_BIND_TYPE_PER_PASSENGER = 'perPassenger';
export const SERVICE_BIND_TYPE_PER_DAY = 'perDay';
export const SERVICE_BIND_TYPE_PER_BOOKING = 'perBooking';
export const SERVICE_BIND_TYPE_PER_FLIGHT = 'perFlight';
export const SERVICE_BIND_TYPE_PER_DAY_PER_PERSON = 'perDayPerPerson';

export const SERVICE_NAME_AIRPORT_TRANSFERS = 'airportTransfers';
export const SERVICE_NAME_WIZZ_FLEX = 'wizzFlex';
export const SERVICE_NAME_SEATING_TOGETHER_GUARANTEE = 'seatingTogetherGuarantee';

export const SERVICE_SEATING_TOGETHER_GUARANTEE = 'seating-together-guarantee';

export const PRIORITY_BOARDING_ICON = 'wizz_priority_default_icon';

export const LOCALIZATION = metadata;

// Floodlight
export const FLOODLIGHT_SALES_SALES = 'sale0/sales00+transactions';
export const FLOODLIGHT_COUNT_RESULT = 'count00/resul0+standard';
export const FLOODLIGHT_COUNT_SUBSCRIBE = 'count00/sub0+standard';
export const FLOODLIGHT_COUNT_ACCOUNT = 'count00/acc0+standard';
export const FLOODLIGHT_COUNT_BOOKING = 'count00/book0+standard';
export const FLOODLIGHT_COUNT_ALLPAGES = 'count00/allpa0+standard';
export const FLOODLIGHT_VARIABLES_KEY_MAP = Object.freeze({
  pageUrl: 'u1',
  pnr: 'u2',
  totalAmount: 'u3',
  currency: 'u4',
  departureStation: 'u5',
  arrivalStation: 'u6',
  departureDate: 'u7',
  returnDate: 'u8',
  selectedPackagePrice: 'u9',
  numberOfPassengers: 'u10',
  isUserLoggedIn: 'u11',
  hasPriorityBoarding: 'u12',
  hasWizzFamily: 'u13',
  cabinBaggageType: 'u14',
  hasCheckInBaggage: 'u15',
  checkInBaggagePrice: 'u16',
});

export const FEE_NAME_DISTRIBUTION = 'distribution';
export const FEE_NAME_INFANT = 'infant';
export const FEE_NAME_ADMINISTRATION = 'administration';
export const FEE_NAME_GROUP_ADMINISTRATION = 'groupAdministration';
export const FEE_NAME_FLEXIBLE_TRAVEL_PARTNER = 'flexibleTravelPartner';
export const FEE_NAME_FLIGHT_CHANGE = 'flightChange';
export const FEE_NAME_NAME_CHANGE = 'nameChange';
export const FEE_NAME_SEAT = 'seat';
export const FEE_NAME_PRIORITY_BOARDING = 'priorityBoarding';
export const FEE_NAME_SEATING_TOGETHER = 'seatingTogetherGuarantee';
export const FEE_NAME_GO_FARE_UPSELL = 'middleFareUpsell';

export const FEE_CODES = {
  [FEE_NAME_INFANT]: 'INFT',
  [FEE_NAME_ADMINISTRATION]: 'ADM',
  [FEE_NAME_GROUP_ADMINISTRATION]: 'ADG',
  [FEE_NAME_FLEXIBLE_TRAVEL_PARTNER]: 'UNTP',
  [FEE_NAME_FLIGHT_CHANGE]: 'FCHG',
  [FEE_NAME_NAME_CHANGE]: 'NCHG',
  [FEE_NAME_SEAT]: 'SEAT',
  [FEE_NAME_PRIORITY_BOARDING]: 'PRB',
  [FEE_NAME_SEATING_TOGETHER]: 'STTG',
  [FEE_NAME_GO_FARE_UPSELL]: 'STRD',
};

export const FEE_CODES_FOR_CAR_TRAWLER = [...BUNDLES, FEE_CODES[FEE_NAME_ADMINISTRATION]];

export const SERVICE_FEE_NAME_PRIORITY_BOARDING = 'priority-boarding';
export const SERVICE_FEE_NAME_AIRPORT_CHECKIN = 'airport-checkin';
export const SERVICE_FEE_LOCATION_AIRPORT = 'airport';

// Gift voucher

export const VOUCHER_LAYOUT_CLASS_PREFIX = 'voucher-layout-';

// Itinerary infant
export const ITINERARY_INFANT_PREFIX = 'infant-';

export const BOOKING_FLOW_HEADER = '.body--booking-flow header.header';
export const BODY_MODAL_VISIBLE_CLASS = 'body--modal-visible';

// Affiliates / booking.com

// "en-gb", "en-us", "de", "ru", "zh-cn", "nl", "fr", "es", "es-ar",
// "ca", "it", "pt-pt", "pt-br", "no", "fi", "sv", "da", "cs", "hu",
// "ro", "ja", "zh-cn", "zh-tw", "pl", "el", "ru", "tr", "bg", "ar",
// "ko", "he", "lv", "uk", "id", "ms", "th", "et", "hr", "lt", "sk",
// "sr", "sl", "vi", "tl", "is"

export const BOOKING_DOT_COM_SUPPORTED_LANGUAGES = {
  'hu-hu': 'hu',
  'bg-bg': 'bg',
  'cs-cz': 'cs',
  // 'bs-latn-ba': '',
  'de-de': 'de',
  'en-gb': 'en-gb',
  'es-es': 'es',
  'ca-es': 'ca',
  'fr-fr': 'fr',
  'he-il': 'he',
  'it-it': 'it',
  'lv-lv': 'lv',
  // 'mk-mk': '',
  'lt-lt': 'lt',
  'nl-nl': 'nl',
  'nb-no': 'no',
  'pl-pl': 'pl',
  'pt-pt': 'pt-pt',
  'ro-ro': 'ro',
  'sk-sk': 'sk',
  'sv-se': 'sv',
  'sr-cyrl-cs': 'sr',
  'uk-ua': 'uk',
  'ar-ae': 'ar',
};

export const BOOKING_DOT_COM_FALLBACK_LANGUAGE = LANGUAGE_CODE_EN_GB;

// Affiliates / rentalcars.com

// "id", "my", "da", "ee", "ph", "hr", "lv", "lt", "hu", "nl",
// "no", "pl", "br", "pt", "ru", "ro", "sk", "sl", "rs", "fi",
// "sv", "sw", "tr", "vi", "is", "gr", "bg", "uk", "ar", "th",
// "ja", "zs", "zh", "ko"

export const RENTAL_CARS_DOT_COM_SUPPORTED_LANGUAGES = {
  'bg-bg': 'bg',
  // 'bs-latn-ba': '',
  'ca-es': 'ca',
  'cs-cz': 'cs',
  'de-de': 'de',
  'el-gr': 'gr',
  'en-gb': 'en',
  'es-es': 'es',
  'fr-fr': 'fr',
  'he-il': 'he',
  'hu-hu': 'hu',
  'it-it': 'it',
  // 'ka-ge': '',
  'lt-lt': 'lt',
  'lv-lv': 'lv',
  // 'mk-mk': '',
  'nb-no': 'no',
  'nl-nl': 'nl',
  'pl-pl': 'pl',
  'pt-pt': 'pt',
  'ro-ro': 'ro',
  // 'sk-sk': 'sk',
  // 'sq-al': 'all',
  'sr-cyrl-cs': 'rs',
  'sv-se': 'sv',
  'uk-ua': 'uk',
  'ar-ae': 'ar',
};

export const RENTALCARS_FREE_TEXT_SEARCH_SUPPORTED_LANGUAGES = [
  'ca-es',
  'cs-cz',
  'de-de',
  'en-gb',
  'es-es',
  'fr-fr',
  'hu-hu',
  'it-it',
  'lt-lt',
  'lv-lv',
  'nb-no',
  'nl-nl',
  'pl-pl',
  'pt-pt',
  'ro-ro',
  'sk-sk',
  'sv-se',
];

export const BREEZE_SIM_URL = 'https://wizz.breezesim.com/';

export const RENTAL_CARS_DOT_COM_FALLBACK_LANGUAGE = 'en';

export const CAR_TRAWLER_SUPPORTED_LANGUAGES = {
  'bg-bg': 'bg',
  'ca-es': 'ca',
  'cs-cz': 'cs',
  'de-de': 'de',
  'el-gr': 'gr',
  'en-gb': 'en',
  'es-es': 'es',
  'fr-fr': 'fr',
  'hu-hu': 'hu',
  'it-it': 'it',
  'lt-lt': 'lt',
  'lv-lv': 'lv',
  'mk-mk': 'mk',
  'nb-no': 'no',
  'nl-nl': 'nl',
  'pl-pl': 'pl',
  'pt-pt': 'pt',
  'ro-ro': 'ro',
  'sr-cyrl-cs': 'rs',
  'sv-se': 'sv',
  'uk-ua': 'uk',
  'ar-ae': 'ar',
};

export const CAR_TRAWLER_FALLBACK_LANGUAGE = 'en';

export const CAR_TRAWLER_VIEW_GRID = 'GRID';
export const CAR_TRAWLER_VIEW_SELECTED = 'SELECTED';
export const CAR_TRAWLER_VIEW_LIST = 'LIST';
export const CAR_TRAWLER_VIEW_FROM_BASKET = 'BASKET';
export const CAR_TRAWLER_ADD_TO_BASKET = 'ADD';
export const CAR_TRAWLER_REMOVE_FROM_BASKET = 'REMOVE';
export const CAR_TRAWLER_STATE_CHANGE = 'CHANGE';
export const CAR_TRAWLER_INPATH = 'inpath';

export const E_SIM_DATA_ROAMING_SUPPORTED_LANGUAGES = {
  'ar-ae': 'ar',
  'it-it': 'it',
  'sq-al': 'sq',
  'hu-hu': 'hu',
};

// Triggers
export const TRIGGER_CONTINUE_TO_CHECK_IN_CLICK = 'continue-to-check-in-click';

// Labels
export const LABEL_CONTINUE = 'continue';
export const LABEL_CONFIRM = 'confirm';
export const LABEL_CONTINUE_TO_RETURN_FLIGHT = 'continue-to-return-flight';
export const LABEL_CONTINUE_TO_THE_SEAT_MAP = 'continue-to-seat-map';

// Currencies

export const CURRENCY_CODE_AED = 'AED';
export const CURRENCY_CODE_AZN = 'AZN';
export const CURRENCY_CODE_BAM = 'BAM';
export const CURRENCY_CODE_BGN = 'BGN';
export const CURRENCY_CODE_CHF = 'CHF';
export const CURRENCY_CODE_CZK = 'CZK';
export const CURRENCY_CODE_DKK = 'DKK';
export const CURRENCY_CODE_EUR = 'EUR';
export const CURRENCY_CODE_GBP = 'GBP';
export const CURRENCY_CODE_GEL = 'GEL';
export const CURRENCY_CODE_HUF = 'HUF';
export const CURRENCY_CODE_ILS = 'ILS';
export const CURRENCY_CODE_MKD = 'MKD';
export const CURRENCY_CODE_NOK = 'NOK';
export const CURRENCY_CODE_PLN = 'PLN';
export const CURRENCY_CODE_RON = 'RON';
export const CURRENCY_CODE_RSD = 'RSD';
export const CURRENCY_CODE_RUB = 'RUB';
export const CURRENCY_CODE_SEK = 'SEK';
export const CURRENCY_CODE_UAH = 'UAH';
export const CURRENCY_CODE_USD = 'USD';
export const CURRENCY_CODE_ALL = 'ALL';

export const FORWARD = 'forward';
export const BACKWARD = 'backward';

// If you're wondering what the heck is kobalos:
// https://devops.webdev-wizzair.local/DefaultCollection/WizzDev/_git/front-end-extensions
export const KOBALOS_API_OVERRIDE_KEY = 'kobalos-override-url';
export const KOBALOS_SIMULATE_OUTAGE_KEY = 'wizz_kobalos__simulage_outage';
export const KOBALOS_SIMULATE_503_RESPONSE_ON_NEXT_API_CALL =
  'kobalos_simulate503ResponseOnNextApiCall';
export const KOBALOS_SIMULATE_451_RESPONSE_ON_PAGE_LOAD =
  'kobalos_simulate451ResponseOnPageLoad';
export const KOBALOS_SIMULATE_451_RESPONSE_ON_NEXT_API_CALL =
  'kobalos_simulate451ResponseOnNextApiCall';
export const KOBALOS_SIMULATE_BLOCKING_CAPTCHA_ON_NEXT_API_CALL =
  'kobalos_simulateBlockingCaptchaOnNextApiCall';
export const KOBALOS_SIMULATE_NON_BLOCKING_CAPTCHA_ON_NEXT_API_CALL =
  'kobalos_simulateNonBlockingCaptchaOnNextApiCall';
export const KOBALOS_FORCE_SUCCESSFUL_BOOKING = 'kobalos_forceSuccessfulBooking';
export const KOBALOS_DISABLE_SENTRY = 'kobalos_disableSentry';
export const KOBALOS_DISABLE_CHECK_IN_GUARD = 'kobalos_disableCheckInGuard';
export const KOBALOS_ONE_MINUTE_FLASH_PROMO = 'kobalos_oneMinuteFlashPromo';
export const KOBALOS_RATING_MODAL = 'kobalos_ratingModal';
export const KOBALOS_SUCCESSFUL_BOOKING_AFFILIATE = 'kobalos_successfulBookingAffiliate';
export const KOBALOS_OVERRIDE_AKAMAI_COUNTRY_CODE = 'kobalos-override-user-country-code';

export const PAYMENT_OPTION_CREDIT_CARD = 'creditCardPaymentOption';
export const PAYMENT_OPTION_STORED_CREDIT_CARD = 'storedCreditCardPaymentOption';
export const PAYMENT_OPTION_BANK_TRANSFER = 'bankTransferPaymentOption';
export const PAYMENT_OPTION_I_DEAL = 'iDealPaymentOption';
export const PAYMENT_OPTION_PAY_BY_LINK = 'payByLinkPaymentOption';
export const PAYMENT_OPTION_TRUSTLY = 'trustlyPaymentOption';
export const PAYMENT_OPTION_COUPON = 'couponOption';
export const PAYMENT_OPTION_VOUCHER = 'voucherPaymentOption';
export const PAYMENT_OPTION_WIZZ_ACCOUNT = 'customerAccountPaymentOption';
export const PAYMENT_OPTION_GOOGLE_PAY = 'googlePayPaymentOption';
export const GOOGLE_PAY_CANCELED_STATUS = 'CANCELED';

export const PAYMENT_STATUS_NEW = 'new';
export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_ACCEPTED = 'accepted';
export const PAYMENT_STATUS_DECLINED = 'declined';

export const FLASH_ITINERARY_PAYMENT_IS_CANCELLED = 'flashItineraryPaymentIsCancelled';

export const CATEGORY_REFUND_TO_WIZZ_ACCOUNT = 'Refund to Wizz account';

export const LINK_KEYS_TO_TRACK_CONTENT_FROM_CMS = {
  'ga-track-explore-links': [
    'gaTrackExploreLinkFareFinder',
    'gaTrackExploreLinkTripPlanner',
    'gaTrackExploreLinkDestination',
    'gaTrackExploreLinkFlights',
  ],
};
export const POPUNDER_QUERY_STRING_IDENTIFIER = 'ref=popunder';

export const SUMMARY_SECTION_FLIGHTS = 'flights';

export const ITINERARY_STATUS_DIVIDE_ERROR = 'divideError';
export const ITINERARY_STATUS_REACCOMODATE_ERROR = 'reaccomodateError';

export const ITINERARY_FLOW_STANDARD = 'standard';
export const ITINERARY_FLOW_CHECK_IN = 'check-in';
export const ITINERARY_FLOW_ADD_BAGS = 'add-bags';
export const ITINERARY_FLOW_NAME_CHANGE = 'name-change';

export const ITINERARY_SECTION_BOOKING_DETAILS = 'booking-details';
export const ITINERARY_SECTION_ADD_BAGS = 'add-bags';
export const ITINERARY_SECTION_PASSENGER_DETAILS = 'passenger-details';

export const ITINERARY_MODAL_PASSENGER_INFORMATION = 'passenger-information';
export const ITINERARY_MODAL_CHECK_IN = 'check-in';
export const ITINERARY_MODAL_CONFIRM = 'confirm-modal';
export const ITINERARY_MODAL_SERVICES_CONFIRM = 'confirm-services-modal';
export const ITINERARY_MODAL_MAKE_CHANGES = 'make-changes';
export const ITINERARY_MODAL_CONTACT_UPDATE = 'contact-update';
export const ITINERARY_MODAL_NAME_CHANGE = 'name-change-modal';
export const ITINERARY_MODAL_FLIGHT_CHANGE_WARNING = 'flight-change-warning';
export const ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER =
  'modify-booking-flexible-travel-partner';
export const ITINERARY_MODAL_AIRPORT_TRANSFER = 'airport-transfer';
export const ITINERARY_MODAL_AIRPORT_PARKING = 'airport-parking';
export const ITINERARY_MODAL_ACORD_SERVICE = 'acord-service';
export const MODAL_ACORD_SERVICE_INFO = 'acord-service-info';
export const ITINERARY_MODAL_BUNDLE_UPSELL = 'upgrade-bundle-modal';

export const NAME_CHANGE_RESTRICTION_VALUE_WDC = 'wdc';
export const NAME_CHANGE_RESTRICTION_VALUE_PRIVILEGE_PASS = 'privilegePass';
export const NAME_CHANGE_RESTRICTION_VALUE_ALREADY_ASSOCIATED_WITH_CUSTOMER =
  'alreadyAssociatedWithCustomer';
export const NAME_CHANGE_RESTRICTION_VALUE_TIME = 'time';
export const NAME_CHANGE_RESTRICTION_VALUE_WIZZ_ACCOUNT_ALREADY_USED =
  'WizzAccountAlreadyUsed';
export const NAME_CHANGE_RESTRICTION_VALUE_WIZZMEMBERSHIP = 'wizzMembership';

export const NAME_CHANGE_RESTRICTION_LABELS = new Map();
NAME_CHANGE_RESTRICTION_LABELS.set(NAME_CHANGE_RESTRICTION_VALUE_WDC, 'wdc');
NAME_CHANGE_RESTRICTION_LABELS.set(NAME_CHANGE_RESTRICTION_VALUE_PRIVILEGE_PASS, 'pp');
NAME_CHANGE_RESTRICTION_LABELS.set(NAME_CHANGE_RESTRICTION_VALUE_TIME, 'time');
NAME_CHANGE_RESTRICTION_LABELS.set(
  NAME_CHANGE_RESTRICTION_VALUE_WIZZ_ACCOUNT_ALREADY_USED,
  'wizzaccount'
);
NAME_CHANGE_RESTRICTION_LABELS.set(
  NAME_CHANGE_RESTRICTION_VALUE_ALREADY_ASSOCIATED_WITH_CUSTOMER,
  'already-associated-with-customer'
);
NAME_CHANGE_RESTRICTION_LABELS.set(
  NAME_CHANGE_RESTRICTION_VALUE_WIZZMEMBERSHIP,
  'wizz-membership'
);

export const ORDERED_NAME_CHANGE_RESTRICTION_VALUES = [
  NAME_CHANGE_RESTRICTION_VALUE_WDC,
  NAME_CHANGE_RESTRICTION_VALUE_PRIVILEGE_PASS,
  NAME_CHANGE_RESTRICTION_VALUE_ALREADY_ASSOCIATED_WITH_CUSTOMER,
  NAME_CHANGE_RESTRICTION_VALUE_WIZZMEMBERSHIP,
];

export const ITINERARY_RELOAD_ISSUER_BULK_UPLOAD = 'bulk-upload';

export const INFANT_AGE_LIMIT_IN_DAYS = 14;

export const MCP_SELECTION_SOURCE = 'MCP on payment page';

export const DEFAULT_SEAT_MAP_WITHOUT_SUMMARY_DELAY_TIME = 3000;
export const SEAT_MAP_WITHOUT_SUMMARY = 'seat_map_without_summary';
export const ITINERARY_CHECK_IN_UPSELL_STEP_PASSENGER = 'passenger';
export const ITINERARY_CHECK_IN_UPSELL_STEP_SEAT_SELECTION = 'seat-selection';
export const ITINERARY_CHECK_IN_UPSELL_STEP_SERVICES = 'services';
export const ITINERARY_CHECK_IN_UPSELL_STEP_END = 'end';

export const ITINERARY_CHECK_IN_UPSELL_STEPS = [
  ITINERARY_CHECK_IN_UPSELL_STEP_PASSENGER,
  ITINERARY_CHECK_IN_UPSELL_STEP_SEAT_SELECTION,
  ITINERARY_CHECK_IN_UPSELL_STEP_SERVICES,
  ITINERARY_CHECK_IN_UPSELL_STEP_END,
];

export const BREADCRUMB_ANIMATION_FORWARD = 'breadcrumb-forward';
export const BREADCRUMB_ANIMATION_BACKWARD = 'breadcrumb-backward';

export const SINGLE_HEADER_TITLE = 'seats';

// masonry details
export const SCROLL_DOWN_DELAY = 6000;
export const SCROLL_DOWN_DURATION = 1000;
export const SCROLL_DOWN_GAP_MOBILE = -100;
export const SCROLL_DOWN_GAP_DESKTOP = -45;

// FLIGHT SELECT

// blocking issues
export const INFANT_LIMIT = 'infantLimit';
export const SOLD_OUT = 'soldOut';
export const OXY_LIMIT = 'oxyLimit';
export const NO_FARES = 'noFares';
export const NOT_ENOUGH_TIME_TO_RETURN = 'notEnoughTimeToReturn';
export const FLIGHT_IN_PAST = 'flightInPast';

export const DISCOUNT_TYPE_NONE = 'none';

export const LABEL_DURATION_H = 'flight-select-duration-h';
export const LABEL_DURATION_M = 'flight-select-duration-m';

// GA Send Event parameters
export const GA_EVENT_CATEGORY_SUMMARY = 'Summary';
export const GA_EVENT_CATEGORY_FEEDBACK_CHECK_IN_FLOW = 'Feedback check-in flow';
export const GA_EVENT_CATEGORY_FEEDBACK_BOOKING_FLOW = 'Feedback booking flow';
export const GA_EVENT_CATEGORY_PASSENGERS = 'Passengers';
export const GA_EVENT_CATEGORY_SEAT_SELECTION = 'Seat selection';
export const GA_EVENT_LABEL_FEEDBACK_NO_THANKS = 'No Thanks';
export const GA_EVENT_CATEGORY_TARGETED_WDC_AT_LOGIN = 'Targeted WDC offer at login';
export const GA_EVENT_CATEGORY_FLIGHT_SELECT = 'Flight Select';
export const GA_EVENT_CATEGORY_CTA_BUTTON = 'CTA Button';
export const GA_EVENT_CATEGORY_OFFSET = 'Offset';
export const GA_EVENT_CATEGORY_URGENCY_MODAL = 'Urgency Modal';
export const GA_EVENT_LABEL_OFFSET_LABEL = 'Clicked on Carbon offset URL';
export const GA_EVENT_LABEL_SESSION_EXPIRES = 'Your session will expire soon';
export const GA_EVENT_LABEL_CLOSE = 'Close';
export const GA_EVENT_LABEL_FARE_LOCK_TOGGLE = 'Fare lock toggle';

export const GA_EVENT_CATEGORY_HAS_WIZZ_ACCOUNT = 'User Has Wizz Account';
export const GA_EVENT_ACTION_WIZZ_ACCOUNT_LOCATION = 'Payment page';
export const GA_EVENT_LABEL_WIZZ_ACCOUNT_APPEARED = 'Appeared';

export const GA_EVENT_CATEGORY_CURRENCY_WARNING = 'Conversion rate warning';

export const GA_EVENT_CATEGORY_BLUE_RIBBON = 'Blue ribbon';
export const GA_EVENT_LABEL_MORE_INFO = 'More information';
export const PRODUCT_NAME_BLUE_RIBBON_BAG_TRACKING = 'Blue ribbon bag tracking';

export const GA_EVENT_ACTION_CLICK = 'Click';
export const GA_EVENT_ACTION_IMPRESSION = 'Impression';
export const GA_EVENT_LABEL_WIZZAIR_LOGO = 'Wizz Air logo ';
export const GA_EVENT_LABEL_EDIT_ICON = 'Edit icon';
export const GA_EVENT_LABEL_SHOW_NEXT_AVAILABLE_FLIGHT = 'Show next available flight';
export const GA_EVENT_LABEL_FARE_CHART_FLIGHT_CHANGE = 'Fare chart flight date change';
export const GA_EVENT_LABEL_ADD_INBOUND_FLIGHT = 'Add inbound flight';
export const GA_EVENT_LABEL_NO_SEAT_CLICK = 'no-seat-click';
export const GA_EVENT_LABEL_SELECT_FOR_BOTH_WAYS =
  'I select the same for both ways - Unchecked';
export const GA_EVENT_LABEL_CHOOSE_SEATS_LATER = 'Choose Seats Later';
export const GA_EVENT_LABEL_ASSIGN_RANDOM_SEAT = 'Assign Random Seat';
export const GA_EVENT_LABEL_CONTINUE_BUTTON = 'Continue button';
export const GA_EVENT_LABEL_START_NEW_SEARCH = 'Start new search';

export const GA_EVENT_CATEGORY_BOT_DETECTION = 'Bot Detection';
export const GA_EVENT_LABEL_BOT_DETECTION_DETECTED = 'Bot detected';
export const GA_EVENT_LABEL_FLIGHT_SELECT = 'Flight Select';

export const GA_CATEGORY_TRAVEL_PLANNING_MAP = 'Travel Planning Map';

export const INSURANCE_TYPE_CANCELLATION = 'cancellation';
export const INSURANCE_TYPE_TRAVEL = 'travel';
export const INSURANCE_TYPE_TRAVEL_ONLY = 'onlytravel';
export const INSURANCE_TYPE_BAGGAGE = 'baggage';

export const ANCILLARY_ULTRALIGHT_CHECKED_IN_BAGGAGE_LABEL_KEY =
  'bundle-ancillary-ultralight-checked-in-baggage';
export const ANCILLARY_ULTRALIGHT_CHECKED_IN_BAGGAGE_LABEL_KEY_SMART =
  'bundle-ancillary-ultralight-checked-in-baggage-smartab';
export const ANCILLARY_HEAVY_CHECKED_IN_BAGGAGE_LABEL_KEY =
  'bundle-ancillary-heavy-checked-in-baggage';
export const ANCILLARY_HEAVY_CHECKED_IN_BAGGAGE_LABEL_KEY_SMART =
  'bundle-ancillary-heavy-checked-in-baggage-smartab';

export const STATUS_TYPE_ADD = 'add';
export const STATUS_TYPE_ADDED = 'added';

export const PAYMENT_FAILED_SCROLL_TIMEOUT = 5000;
export const AGENCY_SUCCESSFUL_WA_PAYMENT_TIMEOUT = 5000;

export const BOOKING_DOT_COM_BASE_URL = 'https://www.booking.com';
export const BOOKING_DOT_COM_SP_URL = 'https://sp.booking.com';
export const BOOKING_DOT_COM_PARTNER_ID = 'wizzair.com';

// booking.com promo on flight-search's hotel tab
export const BOOKING_COM_PROMO_SMALL_RIBBON_LOCALES = [
  'en-gb',
  'he-il',
  'lt-lt',
  'lv-lv',
  'ru-ru',
  'sv-se',
];
export const BOOKING_COM_PROMO_RIBBON_SIZE_SMALL = 50;
export const BOOKING_COM_PROMO_RIBBON_SIZE_NORMAL = 72;
export const BOOKING_COM_PROMO_RIBBON_SIZE_LARGE = 82;

// constants based on the POST 'booking/services' endpoint
export const ANCILLARY_SMS_NOTIFICATION = 'smsNotification';
export const ANCILLARY_AIRPORT_CHECK_IN = 'airportCheckIn';
export const ANCILLARY_AIRPORT_CHECKIN = 'airportCheckin';
export const ANCILLARY_ACORD_SERVICE = 'acordService';
export const ANCILLARY_FAST_TRACK_SECURITY = 'fastTrackSecurity';
export const ANCILLARY_FAST_TRACK_SECURITY_V2 = 'fastTrackSecurityV2';
export const ANCILLARY_LOUNGE_ACCESS = 'loungeAccess';
export const ANCILLARY_LOUNGE_ACCESS_V2 = 'loungeAccessV2';
export const ANCILLARY_DELAY_WARRANTY = 'delayWarranty';
export const ANCILLARY_PRIORITY_BOARDING = 'priorityBoarding';
export const ANCILLARY_PRIORITY_BOARDING_TROLLEY_BAG = 'priorityBoardingTrolleyBag';
export const ANCILLARY_AIRPORT_TRANSFERS = 'airportTransfers';
export const ANCILLARY_WIZZ_FLEX = 'wizzFlex';
export const ANCILLARY_EXCLUSIVE_LOUNGE = 'exclusiveLounge';
export const ANCILLARY_SEAT_RESERVATION = 'seatReservation';
export const ANCILLARY_PRIVILEGE_PASS = 'privilegePass';
export const ANCILLARY_AIRPORT_PARKING = 'airportParking';
export const ANCILLARY_AIRPORTPARKING = 'airportparking';
export const ANCILLARY_AUTO_CHECK_IN = 'autoCheckIn';
export const ANCILLARY_WDC_MEMBERSHIP = 'wdcMembership';
export const ANCILLARY_WDDC_MEMBERSHIP = 'wddcMembership';
export const ANCILLARY_BOOKING_COM = 'bookingCom';
export const ANCILLARY_CAR_RENTAL = 'carRental';
export const ANCILLARY_CAR_RENTAL_SIXT = 'carRentalSixt';
export const ANCILLARY_CAR_TRAWLER = 'carTrawler';
export const ANCILLARY_CAR_SIXT = 'carSixt';
export const ANCILLARY_E_SIM_DATA_ROAMING = 'eSimDataRoaming';
export const ANCILLARY_SEATING_TOGETHER_GUARANTEE = 'seatingTogetherGuarantee';
export const ANCILLARY_CARRY_ON_BAG = 'carryOnBag';
export const ANCILLARY_LIGHTONE = 'lightone';
export const ANCILLARY_LIGHTTWO = 'lighttwo';
export const ANCILLARY_LIGHTTHREE = 'lightthree';
export const ANCILLARY_ULTRALIGHT = 'ultralight';
export const ANCILLARY_CABIN_BAGGAGE = 'cabinBaggage';
export const ANCILLARY_STROLLER = 'stroller';
export const ANCILLARY_BAG = 'bag';
export const ANCILLARY_BAGS = 'bags';
export const ANCILLARY_BAG1 = 'bag1';
export const ANCILLARY_BAG2 = 'bag2';
export const ANCILLARY_BAG3 = 'bag3';
export const ANCILLARY_BAG4 = 'bag4';
export const ANCILLARY_BAG5 = 'bag5';
export const ANCILLARY_BAG6 = 'bag6';
export const ANCILLARY_E_BAG1 = 'eBag1';
export const ANCILLARY_U_BAG = 'uBag';
export const ANCILLARY_U_BAG1 = 'uBag1';
export const ANCILLARY_U_BAG2 = 'uBag2';
export const ANCILLARY_U_BAG3 = 'uBag3';
export const ANCILLARY_U_BAG4 = 'uBag4';
export const ANCILLARY_U_BAG5 = 'uBag5';
export const ANCILLARY_U_BAG6 = 'uBag6';
export const ANCILLARY_M_BAG1 = 'mBag1';
export const ANCILLARY_M_BAG2 = 'mBag2';
export const ANCILLARY_M_BAG3 = 'mBag3';
export const ANCILLARY_M_BAG4 = 'mBag4';
export const ANCILLARY_M_BAG5 = 'mBag5';
export const ANCILLARY_M_BAG6 = 'mBag6';
export const ANCILLARY_SPORTS_EQUIPMENT = 'sportsEquipment';
export const ANCILLARY_BLUE_RIBBON_BAG_TRACKING = 'blueRibbonBag';
export const ANCILLARY_BLUE_RIBBON_BAG = 'blueRibbonBagTracking';
export const ANCILLARY_HAND_BAGGAGE = 'handBaggage';
export const ANCILLARY_SEAT = 'seat';
export const ANCILLARY_ACORD_INSURANCE = 'acordInsurance';
export const ANCILLARY_ACORD_INSURANCE_FISCAL_CODES = 'acordInsuranceFiscalCodes';
export const ANCILLARY_TRANSFER = 'transfer';
export const ANCILLARY_DELAYED_WARRANTY = 'delayedWarranty';
export const ANCILLARY_AIRPORT_TRANSFER = 'airportTransfer';
export const ANCILLARY_CHECKED_IN_BAGGAGE = 'checkedInBaggage';
export const ANCILLARY_L_BAG1 = 'lBag1';
export const ANCILLARY_L_BAG2 = 'lBag2';
export const ANCILLARY_TRANSFER_BUS = 'bus';
export const ANCILLARY_TRANSFER_TAXI = 'taxi';
export const ANCILLARY_TRANSFER_TRAIN = 'train';
export const ANCILLARY_TRANSFER_SHUTTLEBUS = 'shuttlebus';
export const ANCILLARY_TRANSFER_REGIONALTRAIN = 'regionaltrain';
export const ANCILLARY_TRANSFER_TRAVEL_INSURANCE = 'travelInsurance';
export const ANCILLARY_PRM_HEARING_IMPAIRED = 'deaf';
export const ANCILLARY_PRM_MOBILITY_IMPAIRED = 'wheelchair';
export const ANCILLARY_PRM_VISUALLY_IMPAIRED = 'blind';
export const ANCILLARY_CAFE_AND_BOUTIQUE_VOUCHER = 'cafeVoucher';

export const GROUPED_BUNDLE_ANCILLARY_PRB_AND_CHECKIN = 'group-3-middle-prb-and-checkin';

export const BUNDLE_ANCILLARY_CARRY_ON_BAG = 'bundle-ancillary-carry-on-bag';
export const BUNDLE_ANCILLARY_SEAT_SELECTION = 'bundle-ancillary-seat-selection';
export const BUNDLE_ANCILLARY_WIZZ_ACCOUNT_REFUND =
  'bundle-ancillary-wizz-account-refund';
export const BUNDLE_ANCILLARY_AUTO_CHECK_IN = 'bundle-ancillary-auto-check-in';
export const BUNDLE_ANCILLARY_PRIORITY_BOARDING = 'bundle-ancillary-priority-boarding';
export const BUNDLE_ANCILLARY_AIRPORT_CHECK_IN = 'bundle-ancillary-airport-check-in';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_2_DAYS =
  'bundle-ancillary-2-day-check-in-window';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_2_DAYS_SMART =
  'bundle-ancillary-2-day-check-in-window-smartab';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_30_DAYS =
  'bundle-ancillary-30-day-check-in-window';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_30_DAYS_SMART =
  'bundle-ancillary-30-day-check-in-window-smartab';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_2_DAYS_CUSTOM =
  'bundle-ancillary-2-day-check-in-window-custom';
export const BUNDLE_ANCILLARY_ONLINE_CHECK_IN_2_DAYS_CUSTOM_SMART =
  'bundle-ancillary-2-day-check-in-window-custom-smartab';
export const BUNDLE_ANCILLARY_FLIGHT_INFO = 'bundle-ancillary-flightinformation';
export const BUNDLE_ANCILLARY_WIZZ_FLEX = 'bundle-ancillary-wizz-flex';
export const BUNDLE_ANCILLARY_PRIORITY_CHECK_IN = 'bundle-ancillary-priority-check-in';
export const BUNDLE_ANCILLARY_TROLLEY_BAG = 'bundle-ancillary-trolley-bag';
export const BUNDLE_ANCILLARY_SEATING_TOGETHER_GUARANTEE =
  'bundle-ancillary-seating-together-guarantee';

export const BUNDLE_ANCILLARIES_IN_ORDER = [
  BUNDLE_ANCILLARY_CARRY_ON_BAG,
  BUNDLE_ANCILLARY_ONLINE_CHECK_IN_30_DAYS,
  BUNDLE_ANCILLARY_TROLLEY_BAG,
  ANCILLARY_HEAVY_CHECKED_IN_BAGGAGE_LABEL_KEY,
  BUNDLE_ANCILLARY_SEAT_SELECTION,
  BUNDLE_ANCILLARY_PRIORITY_BOARDING,
  BUNDLE_ANCILLARY_PRIORITY_CHECK_IN,
  BUNDLE_ANCILLARY_AIRPORT_CHECK_IN,
  BUNDLE_ANCILLARY_WIZZ_FLEX,
  BUNDLE_ANCILLARY_WIZZ_ACCOUNT_REFUND,
  BUNDLE_ANCILLARY_AUTO_CHECK_IN,
];

// LIVE FEEDBACK
export const BOOKING_FLOW = 'bookingFlow';
export const ITINERARY = 'itinerary';
export const CHECK_IN_FLOW = 'checkIn';
export const EXTRA_QUESTION_LIMIT_RATE = 4;
export const BOOKING_TITLE = 'booking';
export const CHECK_IN_TITLE = 'checkin';
export const SUBMIT = 'Submit';
export const NO_THANKS = 'NoThanks';

export const GROUP_BOOKING = 'groupbooking';

export const NEW_SEAT_STATUS_STAND_BY = 'STBY';
export const REFUND_TYPE_SEAT = 'refundSeat';

export const MODAL_SIZE_SMALL = 'small';
export const MODAL_SIZE_MEDIUM = 'medium';
export const MODAL_SIZE_LARGE = 'large';
export const MODAL_SIZE_EXTRA_LARGE = 'extra-large';

// modal sizes
export const MODAL_SIZES = [
  MODAL_SIZE_SMALL,
  MODAL_SIZE_MEDIUM,
  MODAL_SIZE_LARGE,
  MODAL_SIZE_EXTRA_LARGE,
];

export const BAGGAGE = 'baggage';

export const SIMPLE_LOCATION_TRACKABLE_PRODUCTS = [
  ANCILLARY_PRIORITY_BOARDING,
  ANCILLARY_SMS_NOTIFICATION,
  ANCILLARY_SEATING_TOGETHER_GUARANTEE,
  ANCILLARY_AIRPORT_CHECK_IN,
  WDC_MEMBERSHIP,
  ANCILLARY_WIZZ_FLEX,
  ANCILLARY_ACORD_SERVICE,
  ANCILLARY_AUTO_CHECK_IN,
];

export const PER_PASSENGER_LOCATION_TRACKABLE_PRODUCTS = [BAGGAGE];

export const BUNDLE_UPSELL_STEP_ADD_SERVICE = 'addService';

export const BAD_REQUEST_ENDPOINT_URL = 'simulate-400-error-response';
export const UNAVAILABLE_FOR_LEGAL_REASONS_ENDPOINT_URL = 'simulate-451-error-response';
export const SERVICE_UNAVAILABLE_ENDPOINT_URL = 'simulate-503-error-response';

// Flight status
export const FLIGHT_STATUS_TAB_IATA = 'iata';
export const FLIGHT_STATUS_TAB_FLIGHT_NUMBER = 'flight-number';

export const BOOKING_IS_UNDER_MODIFICATION = 'BookingIsUnderModification';
export const BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'BookingHasBeenCancelledDueToGovernmentReasons';
export const BOOKING_HAS_BEEN_AUTOMATIC_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'BookingHasBeenAutomaticCancelledDueToGovernmentReasons';
export const AGENCY_BOOKING_HAS_BEEN_AUTOMATIC_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'AgencyBookingHasBeenAutomaticCancelledDueToGovernmentReasons';
export const AGENCY_BOOKING_HAS_BEEN_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'AgencyBookingHasBeenCancelledDueToGovernmentReasons';
export const BOOKING_HAS_BEEN_UNDERPAID_NEVER_BALANCE_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'BookingHasBeenUnderpaidNeverBalanceCancelledDueToGovernmentReasons';
export const AGENCY_BOOKING_HAS_BEEN_UNDERPAID_NEVER_BALANCE_CANCELLED_DUE_TO_GOVERNMENT_REASONS =
  'AgencyBookingHasBeenUnderpaidNeverBalanceCancelledDueToGovernmentReasons';

export const GENDER_ICON_MAN = 'man';
export const GENDER_ICON_WOMAN = 'woman';
export const GENDER_ICON_UNKNOWN = 'unknown';
export const GENDER_ICON_CHILD = 'child';
export const GENDER_ICON_INFANT = 'infant';

export const GENDER_ICONS = [
  GENDER_ICON_MAN,
  GENDER_ICON_WOMAN,
  GENDER_ICON_UNKNOWN,
  GENDER_ICON_CHILD,
  GENDER_ICON_INFANT,
];

export const AUTO_CHECK_IN_LIMIT_IN_HOURS = 48;

export const SEAT_SELECTION_ACCORDION_RECOMMENDATION_NAME = 'recommendedSeats';
export const SEAT_SELECTION_ACCORDION_SELECTION_NAME = 'seatselection';
export const SEAT_SELECTION_ACCORDION_RANDOM_SEATS_NAME = 'randomseats';
export const SEAT_SELECTION_ACCORDION_SITTING_TOGETHER_NAME = 'sitting-together';

export const SEAT_SELECTION_ACCORDION_MAP = new Map()
  .set(SEAT_SELECTION_ACCORDION_RECOMMENDATION_NAME, SEAT_RECOMMENDATION)
  .set(SEAT_SELECTION_ACCORDION_SELECTION_NAME, STEP_SEAT_SELECTION);

export const SEAT_MODIFICATION = 'seat-modification';

export const AVAILABLE_MEMBERSHIPS = 'AVAILABLE_MEMBERSHIPS';

export const MAX_LOCAL_AIRPORT_DISTANCE_KM = 200;

export const PASSENGER_SURNAME = 'surname';

export const CHECK_IN_BAG_TYPES_MAP = new Map()
  .set(ANCILLARY_E_BAG1, {
    count: 1,
    labelKey: 'extralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-extra',
  })
  .set(ANCILLARY_U_BAG1, {
    count: 1,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_U_BAG2, {
    count: 2,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_U_BAG3, {
    count: 3,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_U_BAG4, {
    count: 4,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_U_BAG5, {
    count: 5,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_U_BAG6, {
    count: 6,
    labelKey: 'ultralight-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-light',
  })
  .set(ANCILLARY_M_BAG1, {
    count: 1,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_M_BAG2, {
    count: 2,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_M_BAG3, {
    count: 3,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_M_BAG4, {
    count: 4,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_M_BAG5, {
    count: 5,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_M_BAG6, {
    count: 6,
    labelKey: 'medium-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-medium',
  })
  .set(ANCILLARY_BAG1, {
    count: 1,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  })
  .set(ANCILLARY_BAG2, {
    count: 2,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  })
  .set(ANCILLARY_BAG3, {
    count: 3,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  })
  .set(ANCILLARY_BAG4, {
    count: 4,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  })
  .set(ANCILLARY_BAG5, {
    count: 5,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  })
  .set(ANCILLARY_BAG6, {
    count: 6,
    labelKey: 'heavy-checked-in-bag-with-size',
    icon: 'checked_in_bag_variation3-heavy',
  });

export const BOOKING_MODIFY_TYPE_DIVIDE_FLIGHT_CHANGE = 'divide-flight-change';
export const BOOKING_MODIFY_TYPE_FLIGHT_CHANGE = 'flight-change';
export const BOOKING_MODIFY_TYPE_REBOOK = 'rebook';

export const TOOLTIP_PLACEMENT_BOTTOM_START = 'bottom-start';
export const TOOLTIP_PLACEMENT_BOTTOM_END = 'bottom-end';
export const TOOLTIP_PLACEMENT_TOP_START = 'top-start';
export const TOOLTIP_PLACEMENT_TOP_END = 'top-end';

export const INVALID_DATE_OF_BIRTH_DIFFERENCE_IN_YEARS = 100;

export const FALLBACK_STATIC_SSR_PATH = 'dist-ssr';

export const CHANGES_TO_ACCEPT = {
  GENERAL_CONDITIONS_OF_CARRIAGE_CHANGE: 'carrierForceChangeGccAccept',
};

export const CHECK_IN_TEMPORARY_MESSAGE_START_DATE = '2023-06-12T00:00:00';

export const SPECIAL_ASSISTANCE_VALUE_YES = 'True';
export const SPECIAL_ASSISTANCE_VALUE_NO = 'False';

export const ARRIVAL_ANYWHERE = 'anywhere';

export const ADD_FLIGHT_LOCATION_ITINERARY = 'itinerary';
export const ADD_FLIGHT_LOCATION_ALL_DONE_CHECK_IN = 'all-done-check-in';

export const TWO_RECENT_SEARCH_FLIGHTS = 2;
export const ONE_RECENT_SEARCH_FLIGHT_ON_MOBILE = 1;

// Chatbot
export const CHATBOT_SUPPORTED_LOCALES = ['it-it', 'de-de'];
export const CHATBOT_FALLBACK_LOCALE = 'en-gb';

export const AIRPORT_CHECK_IN_COMPLIANCE_SUPPORTED_LANGUAGES = [
  'de-de',
  'it-it',
  'he-il',
  'el-gr',
  'fr-fr',
  'nl-nl',
];

export const PRM_MODAL_CLOSED_CALL_CENTER_LABEL = 'closed';

export const MOBILE_APP_STORE_DETAILS = [
  {
    store: 'app-store',
    url: 'appStoreUrl',
    icon: {
      name: 'app-store',
      title: 'App Store',
    },
  },
  {
    store: 'google-play',
    url: 'googlePlayStoreUrl',
    icon: {
      name: 'google-play',
      title: 'Google Play',
    },
  },
  {
    store: 'huawei-app-gallery',
    url: 'huaweiAppGalleryUrl',
    icon: {
      name: 'huawei-app-gallery',
      title: 'Huawei App Gallery',
    },
  },
];

// Feature toggle ovverride
export const FEATURE_TOGGLE_OVERRIDE_REGEX = /\/feature-toggle-override\/(.+)/;

export const INSPIRATION_IMAGE_PLACEHOLDER = require('@assets/images/default_banner.jpg');

// Pages that don't exist in the CMS, so we can't load data for them from cms-data
export const FE_ONLY_PAGES = [/^\/booking(\/.*)?$/, /^\/password-reset\/.+/];

export const CANCELLATION_TYPE_BOOKING = 0;
export const CANCELLATION_TYPE_FLIGHT = 1;
export const CANCELLATION_TYPE_PASSENGER = 2;

export const GOOGLE_PAY_BUTTON_COLOR_BLACK = 'black';
export const GOOGLE_PAY_BUTTON_COLOR_WHITE = 'white';
export const GOOGLE_PAY_BUTTON_TYPE_PAY = 'pay';

export const PASSENGER_REDUCED_MOBILITY_NULL = 'PassengerReducedMobilityNull';
export const PASSENGER_REDUCED_MOBILITY_INVALID_COMBINATION =
  'PassengerReducedMobilityInvalidCombination';
export const TRAVEL_PARTNER_CANNOT_BE_PRM = 'TravelPartnerCannotBePRM';
export const PASSENGER_REDUCED_MOBILITY_CONSENT_NOT_ACCEPTED =
  'PassengerReducedMobilityConsentNotAccepted';
export const NEED_SPECIAL_ASSISTANCE_NOT_SPECIFY = 'NeedSpecialAssistanceNotSpecify';
export const REDUCE_MOBILITY_VERSION_NOT_SPECIFY = 'ReduceMobilityVersionNotSpecify';
export const PASSENGER_IS_ALREADY_PRM = 'PassengerIsAlreadyPrm';
export const OUTBOUND_FLIGHT_IS_WHEELCHAIR_LIMIT_EXCEEDED =
  'OutboundFlightIsWheelchairLimitExceeded';
export const OUTBOUND_FLIGHT_IS_ASSISTENT_DOG_LIMIT_EXCEEDED =
  'OutboundFlightIsAssistentDogLimitExceeded';
export const RETURN_FLIGHT_IS_WHEELCHAIR_LIMIT_EXCEEDED =
  'ReturnFlightIsWheelchairLimitExceeded';
export const RETURN_FLIGHT_IS_ASSISTENT_DOG_LIMIT_EXCEEDED =
  'ReturnFlightIsAssistentDogLimitExceeded';

export const PASSENGER_REDUCED_MOBILITY_VISIBLE_ERROR_MESSAGES = [
  PASSENGER_REDUCED_MOBILITY_NULL,
  PASSENGER_REDUCED_MOBILITY_INVALID_COMBINATION,
  TRAVEL_PARTNER_CANNOT_BE_PRM,
  PASSENGER_REDUCED_MOBILITY_CONSENT_NOT_ACCEPTED,

  PASSENGER_IS_ALREADY_PRM,
  OUTBOUND_FLIGHT_IS_WHEELCHAIR_LIMIT_EXCEEDED,
  OUTBOUND_FLIGHT_IS_ASSISTENT_DOG_LIMIT_EXCEEDED,
  RETURN_FLIGHT_IS_WHEELCHAIR_LIMIT_EXCEEDED,
  RETURN_FLIGHT_IS_ASSISTENT_DOG_LIMIT_EXCEEDED,
];

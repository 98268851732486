import arity from '../arity';
import head from '../head';

/**
 * Performs left-to-right function composition. The first argument may have
 * any arity; the remaining arguments must be unary.
 *
 * In some libraries this function is named `sequence`.
 *
 * note: the result of pipe is not automatically curried
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/pipe.js
 *
 * @type {<T extends any[], U>(...fns: Function[]) => (...args: T) => U}
 * @example
 *
 *      const f = pipe(Math.pow, negate, inc);
 *
 *      f(3, 4); // -(3^4) + 1
 */
const pipe = (...fns) => {
  if (fns.length === 1) return head(fns);
  return arity(head(fns).length, fns.reduce(pipe2));
};

/**
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/internal/_pipe.js
 *
 * @type {<T, U1, U2>(f: (...args: T) => U1, g: (a: U1) => U2) => (...args: T) => U2}
 */
const pipe2 =
  (f, g) =>
  (...args) =>
    g(f(...args));

export default pipe;

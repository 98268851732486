import isUndefined from '../is-undefined';

/**
 * Checks if the input value is not `undefined`
 *
 * @example
 *
 *      isNotUndefined(undefined)  // => false
 *      isNotUndefined(null)       // => true
 */
const isNotUndefined = <T>(value: T | undefined): value is T => !isUndefined(value);

export default isNotUndefined;

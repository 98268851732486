import curry from '../curry';

/**
 * @typedef {(prop: string, obj: Object<string, any>) => boolean} HasFn
 */

/**
 * @type HasFn
 */
export const _has = (prop, obj) => Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * Returns whether or not an object has an own property with the specified name
 *
 * based on: https://github.com/ramda/ramda/blob/v0.27.0/source/internal/_has.js
 *
 * @type HasFn
 * @example
 *
 *      let hasName = has('name');
 *      hasName({name: 'alice'});   //=> true
 *      hasName({name: 'bob'});     //=> true
 *      hasName({});                //=> false
 */
const has = curry(_has);

export default has;
